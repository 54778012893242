/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: http://ericmeyer.com
	HTML5 Doctor				:: http://html5doctor.com
	and the HTML5 Boilerplate	:: http://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

article, aside, figure, footer, header, hgroup, nav, section {
  display: block;
}

/* Responsive images and other embedded objects
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
   If this default setting for images is causing issues, you might want to replace it with a .responsive class instead. */
img,
object,
embed {
  max-width: 100%;
}

/* force a vertical scrollbar to prevent a jumpy page */
/*html {overflow-y: scroll;}*/
/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

/*a {margin: 0; padding: 0; font-size:100%; vertical-align:baseline; background: transparent;}*/
del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

pre {
  white-space: pre; /* CSS2 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word; /* IE */
}

input[type=radio] {
  vertical-align: text-bottom;
}

input[type=checkbox] {
  vertical-align: bottom;
  *vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select, input, textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

/* Accessible focus treatment
	people.opera.com/patrickl/experiments/keyboard/test */
a:hover, a:active {
  outline: none;
}

small {
  font-size: 85%;
}

strong, th {
  font-weight: bold;
}

td, td img {
  vertical-align: top;
}

/* Make sure sup and sub don't screw with your line-heights
	gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
button {
  cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0;
}

/* make buttons play nice in IE */
button {
  width: auto;
  overflow: visible;
}

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic;
}

/* prevent BG image flicker upon hover */
.ie6 html {
  filter: expression(document.execCommand("BackgroundImageCache", false, true));
}

/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

/* Ok, this is where the fun starts.
-------------------------------------------------------------------------------*/
/* new Linux- and Windows-friendly sans-serif font stack: http://mky.be/fontstack */
/*body {font: 13px Helmet, Freesans, sans-serif;}*/
/* using local fonts? make sure to read up on Paul Irish's
	Bulletproof @font-face syntax: http://mky.be/font-face/bulletproof/ */
/* we like off-black for text */
/*body, select, input, textarea {color: #333;}

a {color: #03f;}
a:hover {color: #69f;}*/
/* Custom text-selection colors (remove any text shadows: twitter.com/miketaylr/status/12228805301) */
::-moz-selection {
  background: #4c8fc2;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #4c8fc2;
  color: #fff;
  text-shadow: none;
}

/*	j.mp/webkit-tap-highlight-color */
a:link {
  -webkit-tap-highlight-color: #4c8fc2;
}

ins {
  background-color: #0f3796;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #0f3796;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

/* Print styles!
-------------------------------------------------------------------------------*/
/*@media print {



}*/
/* Media queries!
-------------------------------------------------------------------------------*/
/* Trattativa this: www.cloudfour.com/css-media-query-for-mobile-is-fools-gold/ */
/*@media screen and (max-device-width: 480px) {


}*/
/*@media all and (orientation: portrait) {

}

@media all and (orientation: landscape) {

}*/
/*fixes too big font in mobile Safari*/
/*only iPhone*/
@media only screen and (max-device-width: 960px) {
  /*fixes too big font in mobile Safari*/
  html {
    -webkit-text-size-adjust: none;
  }
}
/*
 * 	Core Owl Carousel CSS File
 *	v1.24
 */
/* clearfix */
.owl-carousel .owl-wrapper:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

/* display none until init */
.owl-carousel {
  display: none;
  position: relative;
  width: 100%;
  -ms-touch-action: pan-y;
}

.owl-carousel .owl-wrapper {
  display: none;
  position: relative;
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper-outer {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.owl-carousel .owl-wrapper-outer.autoHeight {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item {
  float: left;
}

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
  cursor: pointer;
}

.owl-controls {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */
.grabbing {
  cursor: url("/resources/images/grabbing.png") 8 8, move;
}

/* fix */
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

/* CSS3 Transitions */
.owl-origin {
  -webkit-perspective: 1200px;
  -webkit-perspective-origin-x: 50%;
  -webkit-perspective-origin-y: 50%;
  -moz-perspective: 1200px;
  -moz-perspective-origin-x: 50%;
  -moz-perspective-origin-y: 50%;
  perspective: 1200px;
}

/* fade */
.owl-fade-out {
  z-index: 10;
  -webkit-animation: fadeOut 0.7s both ease;
  -moz-animation: fadeOut 0.7s both ease;
  animation: fadeOut 0.7s both ease;
}

.owl-fade-in {
  -webkit-animation: fadeIn 0.7s both ease;
  -moz-animation: fadeIn 0.7s both ease;
  animation: fadeIn 0.7s both ease;
}

/* backSlide */
.owl-backSlide-out {
  -webkit-animation: backSlideOut 1s both ease;
  -moz-animation: backSlideOut 1s both ease;
  animation: backSlideOut 1s both ease;
}

.owl-backSlide-in {
  -webkit-animation: backSlideIn 1s both ease;
  -moz-animation: backSlideIn 1s both ease;
  animation: backSlideIn 1s both ease;
}

/* goDown */
.owl-goDown-out {
  -webkit-animation: scaleToFade 0.7s ease both;
  -moz-animation: scaleToFade 0.7s ease both;
  animation: scaleToFade 0.7s ease both;
}

.owl-goDown-in {
  -webkit-animation: goDown 0.6s ease both;
  -moz-animation: goDown 0.6s ease both;
  animation: goDown 0.6s ease both;
}

/* scaleUp */
.owl-fadeUp-in {
  -webkit-animation: scaleUpFrom 0.5s ease both;
  -moz-animation: scaleUpFrom 0.5s ease both;
  animation: scaleUpFrom 0.5s ease both;
}

.owl-fadeUp-out {
  -webkit-animation: scaleUpTo 0.5s ease both;
  -moz-animation: scaleUpTo 0.5s ease both;
  animation: scaleUpTo 0.5s ease both;
}

/* Keyframes */
/*empty*/
@-webkit-keyframes empty {
  0% {
    opacity: 1;
  }
}
@-moz-keyframes empty {
  0% {
    opacity: 1;
  }
}
@keyframes empty {
  0% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes backSlideOut {
  25% {
    opacity: 0.5;
    -webkit-transform: translateZ(-500px);
  }
  75% {
    opacity: 0.5;
    -webkit-transform: translateZ(-500px) translateX(-200%);
  }
  100% {
    opacity: 0.5;
    -webkit-transform: translateZ(-500px) translateX(-200%);
  }
}
@-moz-keyframes backSlideOut {
  25% {
    opacity: 0.5;
    -moz-transform: translateZ(-500px);
  }
  75% {
    opacity: 0.5;
    -moz-transform: translateZ(-500px) translateX(-200%);
  }
  100% {
    opacity: 0.5;
    -moz-transform: translateZ(-500px) translateX(-200%);
  }
}
@keyframes backSlideOut {
  25% {
    opacity: 0.5;
    transform: translateZ(-500px);
  }
  75% {
    opacity: 0.5;
    transform: translateZ(-500px) translateX(-200%);
  }
  100% {
    opacity: 0.5;
    transform: translateZ(-500px) translateX(-200%);
  }
}
@-webkit-keyframes backSlideIn {
  0%, 25% {
    opacity: 0.5;
    -webkit-transform: translateZ(-500px) translateX(200%);
  }
  75% {
    opacity: 0.5;
    -webkit-transform: translateZ(-500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) translateX(0);
  }
}
@-moz-keyframes backSlideIn {
  0%, 25% {
    opacity: 0.5;
    -moz-transform: translateZ(-500px) translateX(200%);
  }
  75% {
    opacity: 0.5;
    -moz-transform: translateZ(-500px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateZ(0) translateX(0);
  }
}
@keyframes backSlideIn {
  0%, 25% {
    opacity: 0.5;
    transform: translateZ(-500px) translateX(200%);
  }
  75% {
    opacity: 0.5;
    transform: translateZ(-500px);
  }
  100% {
    opacity: 1;
    transform: translateZ(0) translateX(0);
  }
}
@-webkit-keyframes scaleToFade {
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
  }
}
@-moz-keyframes scaleToFade {
  to {
    opacity: 0;
    -moz-transform: scale(0.8);
  }
}
@keyframes scaleToFade {
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}
@-webkit-keyframes goDown {
  from {
    -webkit-transform: translateY(-100%);
  }
}
@-moz-keyframes goDown {
  from {
    -moz-transform: translateY(-100%);
  }
}
@keyframes goDown {
  from {
    transform: translateY(-100%);
  }
}
@-webkit-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -webkit-transform: scale(1.5);
  }
}
@-moz-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -moz-transform: scale(1.5);
  }
}
@keyframes scaleUpFrom {
  from {
    opacity: 0;
    transform: scale(1.5);
  }
}
@-webkit-keyframes scaleUpTo {
  to {
    opacity: 0;
    -webkit-transform: scale(1.5);
  }
}
@-moz-keyframes scaleUpTo {
  to {
    opacity: 0;
    -moz-transform: scale(1.5);
  }
}
@keyframes scaleUpTo {
  to {
    opacity: 0;
    transform: scale(1.5);
  }
}
/* @font-face {
    font-family: 'PNewsGothic';
    src: url('../fonts/NewsGothforPorscheW02-Reg.eot');
    src: url('../fonts/NewsGothforPorscheW02-Reg.eot?#iefix') format('embedded-opentype'),
         url('../fonts/NewsGothforPorscheW02-Reg.woff') format('woff'),
         url('../fonts/NewsGothforPorscheW02-Reg.ttf') format('truetype'),
         url('../fonts/NewsGothforPorscheW02-Reg.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PFrankGothic';
    src: url('../fonts/FrankGothforPorscheW02-Cn.eot');
    src: url('../fonts/FrankGothforPorscheW02-Cn.eot?#iefix') format('embedded-opentype'),
         url('../fonts/FrankGothforPorscheW02-Cn.woff') format('woff'),
         url('../fonts/FrankGothforPorscheW02-Cn.ttf') format('truetype'),
         url('../fonts/FrankGothforPorscheW02-Cn.svg#open_sansregular') format('svg');
    font-weight: bold;
    font-style: normal;
} */
@font-face {
  font-family: "pag-iconfont";
  src: url("../fonts/pag-iconfont.eot");
  src: url("../fonts/pag-iconfontd41d.eot?#iefix") format("embedded-opentype"), url("../fonts/pag-iconfont.woff") format("woff"), url("../fonts/pag-iconfont.ttf") format("truetype"), url("../fonts/pag-iconfont.svg#open_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
/*--------------------------
GENERAL
--------------------------*/
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size-adjust: inherit;
  -moz-font-size-adjust: auto;
  -ms-font-size-adjust: auto;
  -o-font-size-adjust: auto;
  -webkit-font-size-adjust: auto;
  /*-ms-word-break: break-all;
  word-break: break-all;

  // Non standard for webkit
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;

  -webkit-hyphenate-before: 2;
  -webkit-hyphenate-after: 3;
  hyphenate-lines: 3;*/
  /*-moz-font-feature-settings: "liga=1, dlig=1";
  -ms-font-feature-settings: "liga", "dlig";
  -webkit-font-feature-settings: "liga", "dlig";
  -o-font-feature-settings: "liga", "dlig";
  font-feature-settings: "liga", "dlig";*/
  /* background:url(../images/bg-blur_struktur.png) 0 0 repeat-x; */
  font-family: "PorscheNext", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background-attachment: fixed;
}

.cf:before,
.cf:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.cf:after {
  clear: both;
}

.cf {
  *zoom: 1;
}

.hide {
  display: none;
}

.margin-top-remove {
  margin-top: 0 !important;
}

.margin-bottom-remove {
  margin-bottom: 0 !important;
}

.margin-remove {
  margin: 0 !important;
}

.padding-top-remove {
  padding-top: 0 !important;
}

a:hover {
  color: #f00;
}

a {
  text-decoration: none;
  color: #000;
}

a:hover,
a.active {
  color: #d5001c;
}

.page-wrapper {
  display: block;
  margin: 0 auto;
  max-width: 120em;
  min-width: 20em;
  background: #fff;
  overflow-x: hidden;
  position: relative;
}

.page-overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.page-overlay.cover {
  z-index: 30;
}

/*
.lt-ie9 .b-page-overlay {
    width: 100em;
}
*/
.right {
  float: right;
}

.left {
  float: left;
}

.button {
  display: inline-block;
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: #3e4146;
  border: none;
  border-radius: 0;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  margin: 2.5em auto;
}

.button:hover,
.button:focus {
  color: #fff;
  background-color: #d5001c;
}

.button.download {
  position: relative;
}

.button.download:before {
  position: relative;
  font-family: "pag-iconfont";
  color: #ffffff;
  content: "\e023";
  font-size: 18px;
  line-height: inherit;
  padding-right: 7px;
  vertical-align: middle;
}

.button.block,
.button.download.block,
.link.block {
  float: left;
  clear: both;
  margin: 0.6em auto;
}

.link.block {
  margin-left: 4px;
}

.button:hover:before,
.button:focus:before {
  color: #fff;
}

.cta .button {
  margin: 0;
  background-color: #960014;
  color: #fff;
  display: block;
  font-size: 0.5em;
}

.cta .button:after {
  font-family: "pag-iconfont";
  content: "\e00d";
  font-size: 1em;
  color: #fff;
  margin-left: 1em;
  vertical-align: middle;
}

.video {
  position: relative;
  display: block;
}

.player {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -32px;
  width: 64px;
  height: 64px;
  background: #000;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
  border-radius: 32px;
  background-color: transparent;
  zoom: 1;
  background-color: rgba(0, 0, 0, 0.8);
  margin-top: -32px;
  cursor: pointer;
  text-align: center;
  font-size: 1em;
  line-height: 64px;
}

.player:before {
  font-family: "pag-iconfont";
  display: inline-block;
  line-height: 64px;
  font-size: 3em;
  color: #fff;
  content: "\e005";
}

.link {
  display: inline-block;
  position: relative;
  margin: 1em 0;
  /*width: 100%;*/
  font-size: 16px;
  line-height: 24px;
}

.privacy a {
  text-decoration: underline;
}

.half-column {
  width: 100%;
}

.full-column {
  width: 100%;
  float: left;
}

.gr-column-large {
  width: 100%;
}

.gr-column-small {
  width: 100%;
}

.form-column {
  width: 100%;
  float: left;
}

@media only screen and (min-width: 569px) {
  .form-column {
    width: 48%;
  }
  .form-column.left {
    margin-right: 4%;
  }
}
.form-column.column--full {
  width: 100%;
}

form {
  width: 100%;
}

form input, select, textarea {
  float: left;
  height: 2.14286em;
  margin-top: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 7px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 14px;
  width: 100%;
  height: 3.07143em;
  margin-bottom: 1.5em;
  outline: 0 !important;
  border: 1px solid #dfdfdf;
  border-radius: 0;
  background-color: #ffffff;
}

form input[type=radio],
form input[type=checkbox] {
  float: left;
  margin: 4px 10px 0 0;
  margin-top: 1px \9 ;
  line-height: normal;
  height: auto;
  width: auto;
  padding: 0;
  vertical-align: middle;
}

fieldset {
  background-color: #F2F2F2;
  padding: 2em;
  margin: 2em 0;
}

textarea {
  height: 20em;
}

select {
  background-image: url("../images/select-arrow.html");
  background-repeat: no-repeat;
  background-size: 12px 7px;
  background-position: right 10px center;
  -webkit-appearance: textfield;
}

label {
  display: block;
  margin-bottom: 0.75em;
  padding-left: 25px;
  font-size: 16px;
}

legend {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  font-family: "PorscheNext";
  font-size: 26px;
  font-weight: 400;
  line-height: normal;
  color: #3a6b9b;
}

.radio-label {
  font-size: 16px;
}

.radio-label a {
  display: inline-block;
  width: auto;
  margin-bottom: 10px;
}

.radio-label p {
  font-size: 16px;
  margin: 0;
  display: block;
}

.radio-container {
  display: inline-block;
  margin: 5px 10px 20px 0;
}

.radio-container input[type=radio] {
  margin-top: 0;
}

.input-label {
  display: block;
  margin-bottom: 8px;
}

.reset {
  margin: 0;
  padding: 0;
}

button[type=submit] {
  margin-top: 0;
}

/*Fading Animation*/
a,
.teaser-link figure .img-wrapper:hover:after,
.teaser-link figure .img-wrapper:focus:after {
  -webkit-transition-property: color, background-color, opacity;
  -moz-transition-property: color, background-color, opacity;
  -o-transition-property: color, background-color, opacity;
  transition-property: color, background-color, opacity;
  -webkit-transition-duration: 0.24s;
  -moz-transition-duration: 0.24s;
  -o-transition-duration: 0.24s;
  transition-duration: 0.24s;
}

/*---------------------------
---------------------------
HEADINGS
---------------------------
---------------------------*/
h1 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  h1 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 22px;
    line-height: 30px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h1 {
    font-size: 22px;
    line-height: 30px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
  }
}
h2,
.title,
.context-menu-title {
  font-size: 26px;
  line-height: 34px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 24px;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  h2,
  .title,
  .context-menu-title {
    font-size: 26px;
    line-height: 34px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  h2,
  .title,
  .context-menu-title {
    font-size: 26px;
    line-height: 34px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h2,
  .title,
  .context-menu-title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 1200px) {
  h2,
  .title,
  .context-menu-title {
    font-size: 36px;
    line-height: 44px;
    font-weight: 300;
  }
}
h3,
.middle-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  h3,
  .middle-title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  h3,
  .middle-title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h3,
  .middle-title {
    font-size: 26px;
    line-height: 32px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 1200px) {
  h3,
  .middle-title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 300;
  }
}
h4 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  h4 {
    font-size: 22px;
    line-height: 30px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h4 {
    font-size: 22px;
    line-height: 30px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 1200px) {
  h4 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
  }
}
h5 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  h5 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  h5 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h5 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 1200px) {
  h5 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
  }
}
/*---------------------------
---------------------------
TOP
---------------------------
---------------------------*/
header {
  position: relative;
  height: 64px;
  z-index: 20;
  background-color: #fff;
}

@media only screen and (min-width: 720px) {
  header {
    height: auto;
    padding-bottom: 43px;
  }
}
.row {
  margin-left: 6.8125%;
  margin-right: 6.8125%;
}

.row.row--small-full {
  margin-left: 0;
  margin-right: 0;
}

@media only screen and (min-width: 480px) {
  .row.row--small-full {
    margin-left: 6.8125%;
    margin-right: 6.8125%;
  }
}
@media only screen and (min-width: 1760px) {
  .row {
    margin-left: 10%;
    margin-right: 10%;
  }
  .row.row--small-full {
    margin-left: 10%;
    margin-right: 10%;
  }
}
/* logo */
.logo-container {
  text-align: center;
  display: inline-block;
  position: relative;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.14) 0 0 8px 0;
  padding: 20px;
  margin-left: 6px;
  height: 80px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 1;
}

.logo-container span {
  display: inline-block;
  padding: 0;
  background: #fff;
  height: 100%;
  z-index: 1;
}

.logo-container span a {
  display: inline-block;
  background: url("../images/logo-new.svg");
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  text-indent: -119988px;
  overflow: hidden;
  width: 76px;
  height: 38px;
  margin-top: 0;
}

.logo {
  position: relative;
}

@media only screen and (min-width: 720px) {
  .logo-container {
    text-align: center;
    display: block;
    position: relative;
    height: 5.375rem;
    box-shadow: none;
    padding: 0;
    padding-right: 7.14286%;
    padding-left: 7.14286%;
    height: 100%;
    margin-left: 0;
    margin-bottom: 4px;
  }
  .logo-container span {
    padding-left: 16px;
    padding-right: 16px;
    background: #fff;
    height: 100%;
    z-index: 1;
  }
  .logo-container span a {
    width: 100px;
    height: 50px;
    margin-top: 22px;
  }
  .logo-container .logo::before {
    content: "";
    border-bottom: 1px solid #dfdfdf;
    font-size: 0;
    position: absolute;
    bottom: 11px;
    right: 0;
    left: 0;
    height: 1px;
    overflow: hidden;
    z-index: -1;
  }
}
@media only screen and (min-width: 1760px) {
  .logo-container {
    padding-left: 10%;
    padding-right: 10%;
  }
}
/* top landing */
.logo-container.container--remove-border {
  margin: 0;
}

.logo-container.container--remove-border .logo {
  margin-bottom: 22px;
}

.logo-container.container--remove-border .logo::before {
  display: none;
}

/*----- OFFCANVAS ----- */
.main-menu,
.main-menu__submenu {
  font-size: 16px;
  background-color: #ffffff;
  overflow: auto;
  z-index: 2;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  overflow-x: hidden;
}

@media only screen and (max-width: 720px) {
  .main-menu,
  .main-menu__submenu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 100px;
    width: 100%;
    height: 100%;
  }
}
.main-menu-wrapper.is-open .main-menu,
.main-menu_container .dropdown.open .main-menu__submenu {
  transform: translateX(0);
}

.main-menu li {
  display: block;
  padding-left: 7.14286%;
  padding-right: 7.14286%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.main-menu li a {
  border-bottom: 1px #ddd solid;
  line-height: 59px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 20px;
  position: relative;
  font-size: 16px;
  display: block;
}

.main-menu li.has-submenu > a::after {
  content: "\e01f";
  font-family: "pag-iconfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  color: #d5001c;
  position: absolute;
  right: 0;
}

.main-menu_container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 100px;
  overflow-y: auto;
}

/* backlink */
.main-menu__backlink {
  padding-left: 7.14286%;
  padding-right: 7.14286%;
  background-color: #efefef;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
}

.main-menu__backlink-text {
  line-height: 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 20px;
  position: relative;
}

.main-menu__backlink-text:before {
  content: "\e020";
  font-family: "pag-iconfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  color: #d5001c;
  position: absolute;
  left: 0;
  font-size: 18px;
}

@media only screen and (min-width: 720px) {
  .main-menu__backlink {
    display: none !important;
  }
}
.main-menu-section {
  position: relative;
  display: inline-block;
  float: left;
}

.main-menu-section.main-menu-section_tools {
  clear: both;
  display: block;
  float: none;
  z-index: 0;
}

/* menu for medium min-width: 720 - max-width: 1023 px */
@media only screen and (min-width: 720px) {
  .main-menu-wrapper {
    margin-left: 7.14286%;
  }
  .main-menu {
    display: block;
    position: relative;
    top: 0;
    right: initial;
    left: 0;
    width: max-content;
    height: auto;
    padding: 0;
    margin-left: 19px;
    transform: none;
    background-color: #ffffff;
    overflow: visible;
    box-sizing: content-box;
    z-index: 50;
    clear: both;
  }
  .main-menu > li {
    width: auto;
    vertical-align: top;
  }
  .main-menu li a {
    position: relative;
    display: block;
    line-height: 2.6875em;
    color: inherit;
    text-decoration: inherit;
    cursor: inherit;
    -webkit-tap-highlight-color: transparent;
    margin-left: 0.9375em;
    margin-right: 0.9375em;
    padding-right: 1.25em;
  }
  .main-menu li.has-submenu > a::after {
    color: inherit;
  }
  .main-menu_container {
    display: none;
    position: static;
    left: initial;
    bottom: initial;
    overflow-y: visible;
  }
  .main-menu-wrapper.is-open .main-menu,
  .main-menu-wrapper.is-open .main-menu > .main-menu__submenu,
  .main-menu_container .dropdown.open .main-menu__submenu {
    display: block;
    transform: none;
  }
  /* submenu */
  .main-menu__submenu {
    position: absolute;
    display: none;
    border: 1px solid #dfdfdf;
    top: initial;
    right: initial;
    left: initial;
    bottom: initial;
    width: auto;
    height: initial;
    overflow: visible;
    transform: none;
    z-index: 50;
  }
  .main-menu__submenu li:hover,
  .main-menu__submenu li:focus,
  .main-menu__submenu li.open {
    background-color: #d5001c;
  }
  .main-menu__submenu li:hover > a,
  .main-menu__submenu li:focus > a,
  .main-menu__submenu li.open > a {
    color: #fff;
    border-top: none;
    margin-top: 0;
  }
  .main-menu__submenu li:hover + li > a,
  .main-menu__submenu li:focus + li > a,
  .main-menu__submenu li.open + li > a {
    border-top-color: #d5001c;
  }
  .main-menu__submenu li {
    display: block;
    /* position: relative; */
    width: auto;
    padding: 0;
  }
  .main-menu__submenu li a {
    margin-top: -1px;
    border-top: 1px solid #dfdfdf;
    border-bottom: none;
    line-height: 44px;
  }
  .main-menu__submenu > li:first-child > a {
    border: none;
    margin-top: 0;
  }
  .main-menu__submenu li a::before {
    content: none;
  }
  .main-menu__submenu li.has-submenu .main-menu__submenu {
    left: 100%;
    top: -1px;
    /* top: 0; */
  }
  /* arrow */
  .main-menu_container.main-menu__submenu > li:nth-child(2)::before {
    content: "";
    left: 10%;
    position: absolute;
    top: -10px;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #cccccc;
    border-left: 10px solid transparent;
  }
  .main-menu_container.main-menu__submenu > li:nth-child(2)::after {
    content: "";
    left: 10%;
    position: absolute;
    top: -9px;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
  }
  .main-menu_container.main-menu__submenu > li:nth-child(2):hover::after,
  .main-menu_container.main-menu__submenu > li:nth-child(2):focus::after,
  .main-menu_container.main-menu__submenu > li:nth-child(2).open::after {
    border-bottom: 10px solid #d5001c;
  }
}
/* menu for large min-width: 1023 px */
@media only screen and (min-width: 1023px) {
  .main-menu {
    margin: 0;
    width: inherit;
  }
  .main-menu_container {
    position: static;
    display: block;
    left: 9.6em;
    z-index: 99;
    background-color: #fff;
  }
  .main-menu_container > li {
    display: inline-block;
    position: relative;
    padding-left: 0;
    padding-right: 0;
    float: left;
  }
  .main-menu_container > li:first-child {
    margin-left: 0;
    padding-left: 0;
  }
  .main-menu_container > li > a {
    display: block;
    padding: 0;
    border: none;
    vertical-align: middle;
    line-height: 43px;
  }
  .main-menu_container > li:last-child > a {
    margin-right: 0;
  }
  .main-menu_container > li > a:after {
    content: none;
    display: none;
  }
  .main-menu_container > li > a:hover,
  .main-menu_container > li > a:focus {
    color: #d5001c;
  }
  .main-menu_container > li a.active {
    border-bottom: 2px solid #d5001c;
  }
  .main-menu_container > li.main-menu__backlink + li > a {
    margin-left: 0;
  }
  .main-menu_container > li .main-menu__submenu {
    display: none;
  }
  .main-menu_container > li:hover .main-menu__submenu,
  .main-menu_container > li:focus .main-menu__submenu {
    display: block;
    transform: none;
  }
  /* arrow */
  .main-menu__submenu > li.first-child::before {
    content: "";
    left: 10%;
    position: absolute;
    top: -10px;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #cccccc;
    border-left: 10px solid transparent;
  }
  .main-menu__submenu > li.first-child::after {
    content: "";
    left: 10%;
    position: absolute;
    top: -9px;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
  }
  .main-menu__submenu > li.first-child:hover::after,
  .main-menu__submenu > li.first-child:focus::after {
    border-bottom: 10px solid #d5001c;
  }
}
@media only screen and (min-width: 1760px) {
  .main-menu-wrapper {
    margin-left: 10%;
  }
}
/* mobile trigger */
.main-menu-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  padding-right: 7.14286%;
  z-index: 1;
}

.main-menu-section__trigger {
  float: left;
  display: inline-block;
  cursor: pointer;
  z-index: 0;
  padding: 17px 0;
  font-size: 14px;
  line-height: 30px;
}

.main-menu-section__text {
  padding: 0 18px;
}

.main-menu-section.main-menu-section_last .main-menu-section__trigger .main-menu-section__text {
  padding-right: 0;
}

.main-menu-section:not(:first-child) .main-menu-section__trigger .main-menu-section__text {
  border-left: 1px solid #ccc;
}

@media only screen and (min-width: 720px) {
  .main-menu-wrapper {
    position: relative;
    display: block;
    top: initial;
    right: initial;
    padding: 0;
    background-color: #fff;
  }
  .main-menu-section {
    position: relative;
    display: inline-block;
  }
  .main-menu-section__trigger {
    padding: 0;
    margin: 0;
    display: block;
    font-size: 16px;
    line-height: 2.6875em;
    position: relative;
    padding-left: 1.25em;
    padding-right: 1em;
    margin-left: 0.9375em;
    margin-right: 0.9375em;
  }
  .main-menu-section__text {
    padding: 0;
  }
  .main-menu-section:first-of-type .main-menu-section__trigger {
    margin-left: 0;
    padding-left: 0;
  }
  .main-menu-section.main-menu-section_last .main-menu-section__trigger {
    margin-right: 0;
    padding-right: 0;
  }
  .main-menu-section:not(:first-child) .main-menu-section__trigger .main-menu-section__text {
    border-left: none;
  }
  .main-menu-section:first-child .main-menu-section__trigger::after {
    border-left: 1px solid #000;
    position: absolute;
    content: "";
    width: 1px;
    height: 14px;
    top: 50%;
    right: -16px;
    transform: translate(0, -50%);
  }
}
@media only screen and (min-width: 1023px) {
  .main-menu-section__trigger {
    display: none;
  }
}
/* --- Mobile Menu Tools Menu --- */
.main-menu-tools {
  position: absolute;
  background-color: #2a4b6d;
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  padding: 16px 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.main-menu-tools__item {
  text-align: center;
  padding: 0 20px;
  line-height: 14px;
  font-size: 16px;
  -webkit-flex-basis: 33%;
  -ms-flex-preferred-size: 33%;
  flex-basis: 33%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
}

.main-menu-tools__item:hover,
.main-menu-tools__item:focus {
  color: #ffffff;
}

.main-menu-tools__item::before {
  font-family: "pag-iconfont";
  font-size: 32px;
  padding: 0 0 8px 0;
  line-height: 1;
  display: block;
}

.main-menu-tools__item.item--configurator::before {
  content: "\e02e";
}

.main-menu-tools__item.item--compare::before {
  content: "\e035";
}

.main-menu-tools__item.item--payment::before {
  content: "\e02c";
}

.main-menu-tools__item:not(:first-of-type) {
  border-left: 1px solid rgba(153, 153, 153, 0.3);
}

@media only screen and (min-width: 720px) {
  .main-menu-tools {
    display: none;
  }
}
/* --- Tools Menu --- */
.tools-menu,
.tools-menu__container {
  display: none;
}

@media only screen and (min-width: 720px) {
  .tools-menu {
    display: inline-block;
  }
  .tools-menu__container {
    display: block;
    z-index: 1;
  }
}
.tools-menu {
  position: absolute;
  right: 7.14286%;
  left: 0;
  padding: 0.68em 2.5%;
  font-size: 16px;
  line-height: 1;
  color: #fff;
  background-color: #2A4B6D;
  z-index: 1;
  cursor: pointer;
}

@media only screen and (min-width: 1760px) {
  .tools-menu {
    right: 10%;
  }
}
.tools-menu:hover,
.tools-menu:focus {
  color: #fff;
}

.tools-menu::after,
.tools-menu:not(.open)::after {
  margin-left: 1.15em;
  font-family: "pag-iconfont";
  content: "\e004";
  /* position: absolute; */
  right: 2.5%;
}

.tools-menu.open::after {
  content: "\e02d";
}

/* .tools-menu.active:after{
	color: #fff;
	font-family: 'pag-iconfont';
	content: "\e01e";
} */
.tools-menu__container.open {
  display: block;
}

.tools-menu__container {
  position: absolute;
  right: 7.14286%;
  left: 0;
  padding-top: 2.6em;
  padding-bottom: 7.5em;
  padding-left: 2.5%;
  padding-right: 2.5%;
  background-color: #2A4B6D;
  z-index: 0;
}

@media only screen and (min-width: 1760px) {
  .tools-menu__container {
    right: 10%;
  }
}
.tools-menu__container a {
  color: #fff;
}

.tools-menu__container__column {
  float: right;
  width: 100%;
}

.tools-menu__container__column .heading {
  display: none;
  margin-top: 0.8em;
  margin-bottom: 0.95em;
  color: #fff;
  font-family: "PorscheNext", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 0.875em;
}

.tools-menu__container__column .heading.visible {
  display: block;
}

.tools-menu__container__column .heading h3 {
  font-weight: normal;
}

.tools-menu__container__column a {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 1;
  padding: 0.83em 0.7em;
  padding: 0.83em 1.5em 0.83em 2.3em;
}

.tools-menu__container__column a:after {
  position: absolute;
  left: 0.95em;
  top: 1.1em;
  font-size: 0.875em;
  color: #fff;
  font-family: "pag-iconfont";
  content: "\e01f";
}

.tools-menu__container__column a:hover,
.tools-menu__container__column a:focus {
  background-color: #687d92;
}

@media only screen and (min-width: 1025px) {
  .tools-menu:hover + .tools-menu__container,
  .tools-menu:focus + .tools-menu__container,
  .tools-menu__container:hover,
  .tools-menu__container:focus,
  .tools-menu__container.open {
    display: block;
  }
  .tools-menu.open::after,
  .tools-menu:hover::after {
    content: "\e02d";
  }
  .tools-menu__container__column a {
    font-size: 16px;
  }
}
@media only screen and (min-width: 720px) {
  .tools-menu {
    left: auto;
    padding: 0.68em 1.88em;
  }
  .tools-menu:after {
    margin-left: 1.15em;
    content: "\e004";
    position: static;
  }
  .tools-menu__container {
    display: none;
    padding-left: 7.14286%;
    padding-right: 2.5em;
  }
  .tools-menu__container__column {
    float: left;
    width: 23%;
    margin-right: 2%;
  }
  .tools-menu__container__column a {
    border-top: 1px solid #405d7b;
    padding: 0.83em 1.5em 0.83em 0.7em;
  }
  .tools-menu__container__column .heading {
    display: block;
    font-size: 1.25em;
  }
  .tools-menu__container__column li:last-child a {
    border-bottom: 1px solid #405d7b;
  }
  .tools-menu__container__column a.configurator,
  .tools-menu__container__column a.comparison,
  .tools-menu__container__column a.financial {
    padding-left: 2.16em;
  }
  .tools-menu__container__column a:after {
    left: auto;
    right: 0.95em;
  }
  .tools-menu__container__column a.configurator:before,
  .tools-menu__container__column a.comparison:before,
  .tools-menu__container__column a.financial:before {
    font-family: "pag-iconfont";
    position: absolute;
    left: 0;
    top: 0.22em;
    font-size: 24px;
    content: "\e02e";
  }
  .tools-menu__container__column a.comparison:before {
    content: "\e035";
  }
  .tools-menu__container__column a.financial:before {
    content: "\e02c";
  }
}
@media only screen and (min-width: 1760px) {
  .tools-menu__container {
    padding-left: 10%;
  }
}
/* MENU BUTTON */
.menu-button-sticky {
  z-index: 999999;
}

.menu-button-headline-wrapper,
.menu-button-wrapper {
  display: block;
  padding: 0;
  background-color: #f2f2f2;
  position: relative;
  z-index: 18;
}

.menu-button-headline-wrapper {
  padding-left: 7.14286%;
}

@media only screen and (min-width: 992px) {
  .menu-button-headline-wrapper,
  .menu-button-wrapper {
    padding: 0 7.14286%;
  }
}
@media only screen and (min-width: 1760px) {
  .menu-button-headline-wrapper,
  .menu-button-wrapper {
    padding: 0 10%;
  }
}
.menu-button {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .menu-button {
    width: 60px;
  }
}
.menu-button-headline {
  position: relative;
  display: block;
  width: 100%;
  margin-left: 0;
  padding-top: 1.25em;
  padding-bottom: 0.625em;
}

.menu-button-headline h1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0;
  color: #000;
  margin-bottom: 0;
  margin-top: 0;
  line-height: normal;
  font-size: 22px !important;
  font-weight: 300;
}

@media only screen and (min-width: 35.5em) and (max-width: 63.875em) {
  .menu-button-headline__text {
    font-size: 1.5em !important;
  }
}
@media only screen and (min-width: 45em) and (max-width: 63.875em) {
  .menu-button-headline h1 {
    margin-right: 7.08333em;
  }
}
@media only screen and (min-width: 63.9375em) {
  .menu-button-headline h1 {
    margin-right: 6.53846em;
    font-size: 24px !important;
  }
}
.menu-button__trigger {
  display: block;
  text-decoration: none;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #900;
  color: #fff;
  outline: 0;
}

.menu-button__trigger::before {
  content: "\e01d";
  font-family: "pag-iconfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: 1.6em;
  line-height: 50px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

@media only screen and (min-width: 768px) {
  .menu-button__trigger::before {
    line-height: 60px;
  }
}
.menu-button__trigger:focus {
  outline: 0;
}

.menu-button__trigger,
.menu-button__link {
  transition: background-color 0.24s, color 0.24s;
}

.menu-button__trigger:hover,
.menu-button.active .menu-button__trigger {
  background-color: #3e4146;
  color: #d5001c;
}

.menu-button__dropdown {
  display: none;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100vw;
  z-index: 18;
  max-height: calc(100vh - 50px);
  overflow-y: scroll;
  background-color: #3e4146;
}

@media only screen and (min-width: 768px) {
  .menu-button__dropdown {
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    width: 320px;
  }
}
.menu-button.active .menu-button__dropdown {
  display: block;
}

.menu-button__dropdown-list {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-button__dropdown-item {
  position: relative;
  line-height: 60px;
  width: 100%;
  overflow: hidden;
  background-color: #3e4146;
}

@media only screen and (min-width: 992px) {
  .menu-button__dropdown-item {
    line-height: 60px;
  }
}
.menu-button__dropdown-list > .menu-button__dropdown-item:not(:first-of-type):not(:last-of-type) > a {
  border-top: 2px solid #636363;
}

.menu-button__dropdown-list > .menu-button__dropdown-item:last-of-type {
  border-top: 2px solid #636363;
}

/* submenu */
.menu-button__dropdown-item > ul > li {
  padding-left: 32px;
}

.menu-button__dropdown-item > ul > li:not(:first-of-type) > a {
  border-top: 2px solid #636363;
}

/* header */
.menu-button__dropdown-item.item--header,
.menu-button__dropdown-header {
  background-color: #960014;
  color: #ffffff;
}

.menu-button__dropdown-item:first-of-type {
  text-transform: uppercase;
}

.menu-button__dropdown-item:hover,
.menu-button__dropdown-item:focus {
  background-color: #960014;
}

.menu-button__dropdown-item:hover .menu-button__dropdown-link,
.menu-button__dropdown-item:focus .menu-button__dropdown-link {
  color: #fff;
}

.menu-button__dropdown-link {
  display: block;
  position: relative;
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  line-height: 60px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px;
  margin: 0 2em;
  max-width: 100%;
}

@media only screen and (min-width: 768px) {
  .menu-button__dropdown-link {
    line-height: 60px;
  }
}
.menu-button__dropdown-link.link--anchor::after,
.menu-button__dropdown-link.link--backtotop::after {
  position: absolute;
  display: block;
  line-height: inherit !important;
  top: 0;
  right: 0;
  font-family: "pag-iconfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: 16px;
  line-height: 50px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.menu-button__dropdown-link.link--anchor::after {
  content: "\e021";
  font-family: "pag-iconfont";
}

.menu-button__dropdown-link.link--anchor.link--active {
  color: #ffffff;
}

.menu-button__dropdown-link.link--anchor.link--active::after {
  content: "\e00d";
  font-family: "pag-iconfont";
}

.menu-button__dropdown-link.link--anchor.link--previous::after {
  content: "\e01e";
  font-family: "pag-iconfont";
}

.menu-button__dropdown-link.link--anchor.link--next::after {
  content: "\e021";
  font-family: "pag-iconfont";
}

.menu-button__dropdown-link.link--backtotop::after {
  content: "\e01e";
  font-family: "pag-iconfont";
}

/* --- Side Navigation --- */
#sidenav {
  position: fixed;
  right: 0px;
  bottom: 40px;
  top: auto;
  z-index: 10;
}

#sidenav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#sidenav ul li {
  position: static;
  background-color: #fff;
  margin: 0;
  padding: 0;
  width: 42px;
  height: 42px;
  font-family: "pag-iconfont";
  border: 1px solid #999;
  border-top: none;
  font-size: 1em;
}

#sidenav ul li.back {
  position: relative;
  background-color: #212121;
  border: none;
  border: 1px solid #999;
}

#sidenav ul li.back a {
  z-index: 10;
}

#sidenav ul li.back:hover:after,
#sidenav ul li.back:focus:after {
  position: absolute;
  display: block;
  height: 40px;
  border-right: 1px solid #212121;
  content: "";
  left: -1px;
  top: 0;
}

#sidenav ul li.back:hover #breadcrumb,
#sidenav ul li.back:active #breadcrumb {
  display: block;
}

#sidenav ul li.back:hover,
#sidenav ul li.back:active {
  border-right: none;
}

#sidenav ul li.down {
  display: none;
}

#sidenav ul li a {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  color: #000;
  cursor: pointer;
}

#sidenav ul li.back a {
  color: #fff;
}

#sidenav ul li.inactive a,
#sidenav ul li.inactive a:hover,
#sidenav ul li.inactive a:focus {
  color: #999;
  cursor: default;
}

#sidenav ul li a:hover,
#sidenav ul li a:focus {
  color: #d5001c;
}

#sidenav ul li:hover.back > a,
#sidenav ul li:focus.back > a {
  color: #d5001c;
}

#sidenav ul li.back a:before,
#sidenav ul li.up a:before,
#sidenav ul li.down a:before {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

#sidenav ul li.back > a:before {
  content: "\e015";
  font-size: 1.375em;
}

#sidenav ul li.up > a:before {
  content: "\e01c";
}

#sidenav ul li.down > a:before {
  content: "\e017";
}

ul#breadcrumb {
  display: none;
  position: absolute;
  bottom: -2px;
  margin-bottom: 1px;
  right: 41px;
  left: auto;
  border: 1px solid #999;
  white-space: nowrap;
  font-size: 0.875em;
}

ul#breadcrumb li {
  height: 40px;
  background-color: #212121;
  width: auto;
  border: none;
}

ul#breadcrumb li:hover,
ul#breadcrumb li:focus {
  background-color: #d5001c;
}

ul#breadcrumb li a {
  margin-left: 21px;
  margin-right: 21px;
  vertical-align: middle;
  line-height: 40px;
  height: 40px;
  display: block;
  width: auto;
  font-family: "PorscheNext", Arial, Helvetica, sans-serif;
  font-weight: 400;
  border-bottom: 1px solid #333;
  cursor: pointer;
}

ul#breadcrumb li:hover a,
ul#breadcrumb li:focus a {
  border-bottom-color: transparent;
}

ul#breadcrumb li:last-child a {
  border-top: none;
}

ul#breadcrumb li a:hover,
ul#breadcrumb li a:focus {
  color: #fff !important;
}

ul#breadcrumb li a:before {
  content: none;
}

/*---------------------------
---------------------------
MAIN - HOMEPAGE
---------------------------
---------------------------*/
#main_slider {
  position: relative;
  /*margin-bottom: 2em;*/
  max-height: 738px;
  overflow: hidden;
}

.slide {
  position: relative;
}

.slide > a, .slide .cta {
  font-family: "PorscheNext", Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;
  text-align: center;
  line-height: 1em;
}

.slide > a, .slide .cta {
  position: absolute;
  left: 0;
  bottom: 2.85714em;
  color: #fff;
  text-align: left;
  font-size: 1.375em;
}

.slide h2::before {
  font-family: "pag-iconfont";
  content: "\e01f";
  color: #d5001c;
  font-size: 22px;
  position: absolute;
  margin-left: -1em;
  width: 1em;
  vertical-align: middle;
}

@media only screen and (min-width: 480px) {
  .slide h2::before {
    font-size: 36px;
  }
}
.slide .cta h2::before {
  content: none;
}

.slide img {
  width: 100%;
  height: auto;
  display: block;
}

.slide-title,
.slide-subtitle {
  position: relative;
  display: block;
  font-family: "PorscheNext", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.625em;
  margin: 0;
  transition: all 0.3s ease;
}

.slide-caption:hover .slide-title,
.slide-caption:hover .slide-subtitle,
.slide-caption:focus .slide-title,
.slide-caption:focus .slide-subtitle {
  border-radius: 4px;
  background: var(--States-pds-state-hover, rgba(148, 149, 152, 0.18));
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
}

.slide-caption[href*="#/"] {
  cursor: default;
}

.slide-caption[href*="#/"]:hover *,
.slide-caption[href*="#/"]:focus * {
  color: inherit;
}

.slide-caption[href*="#/"] > .slide-title::before {
  display: none;
}

/* slide type */
.slide-title {
  font-size: 28px;
  line-height: 36px;
  font-weight: normal;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .slide-title {
    font-size: 40px;
    line-height: 52px;
    font-weight: normal;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide-title {
    font-size: 40px;
    line-height: 52px;
    font-weight: normal;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide-title {
    font-size: 40px;
    line-height: 52px;
    font-weight: normal;
  }
}
@media only screen and (min-width: 1200px) {
  .slide-title {
    font-size: 60px;
    line-height: 72px;
    font-weight: normal;
  }
}
.slide-subtitle {
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .slide-subtitle {
    font-size: 20px;
    line-height: 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide-subtitle {
    font-size: 22px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide-subtitle {
    font-size: 22px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 1200px) {
  .slide-subtitle {
    font-size: 24px;
    line-height: 30px;
  }
}
/* positioning */
.slide-caption.slide-bottomleft,
.slide-caption.slide-bottomright {
  left: 7.14286%;
  right: initial;
  bottom: 45.7142px;
}

.slide-caption.slide-topleft,
.slide-caption.slide-topright {
  top: 45.7142px;
  bottom: initial;
  left: 7.5%;
}

.slide-caption {
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  padding-top: 20px;
  margin: 0 auto;
  max-width: 85%;
}

@media only screen and (min-width: 35.5em) and (max-width: 63.875em) {
  .slide-caption.slide-topleft,
  .slide-caption.slide-topright {
    top: 45.7142px;
  }
  .slide-caption.slidebottomleft,
  .slide-caption.slidebottomright {
    bottom: 45.7142px;
  }
}
@media only screen and (min-width: 63.9375em) {
  .slide-caption.slide-topleft,
  .slide-caption.slide-topright {
    top: 45.7142px;
  }
  .slide-caption.slidebottomleft,
  .slide-caption.slidebottomright {
    bottom: 45.7142px;
  }
}
@media only screen and (min-width: 35.5em) and (max-width: 63.875em) {
  .slide-caption.slidebottomleft,
  .slide-caption.slidebottomright {
    bottom: 36.5714px;
  }
}
@media only screen and (max-width: 35.5625em) {
  .slide-caption.slide-topright,
  .slide-caption.slide-bottomright {
    left: 7.14286%;
    right: initial;
    text-align: left;
  }
  .slide-caption.slide-topleft,
  .slide-caption.slide-bottomleft {
    left: 15%;
    right: initial;
    text-align: left;
  }
  .slide-caption.slidebottomleft,
  .slide-caption.slidebottomright {
    bottom: 45.7142px;
  }
  .slide-caption.slidetopleft,
  .slide-caption.slidetopright {
    top: 45.7142px;
  }
}
@media only screen and (min-width: 35.5em) and (max-width: 63.875em) {
  .slide-caption.slide-topright,
  .slide-caption.slide-bottomright {
    right: 7.14286%;
    left: initial;
    text-align: left;
  }
  .slide-caption.slide-topleft,
  .slide-caption.slide-bottomleft {
    left: 7.14286%;
    right: initial;
    text-align: left;
  }
}
@media only screen and (min-width: 63.9375em) {
  .slide-caption.slide-topright,
  .slide-caption.slide-bottomright {
    right: 7.14286%;
    left: initial;
    text-align: left;
  }
  .slide-caption.slide-topleft,
  .slide-caption.slide-bottomleft {
    left: 7.14286%;
    right: initial;
    text-align: left;
  }
  .slide-caption.slidebottomleft,
  .slide-caption.slidebottomright {
    bottom: 45.7142px;
  }
}
@media only screen and (min-width: 1760px) {
  .slide-caption.slide-topright,
  .slide-caption.slide-bottomright {
    right: 10%;
    left: initial;
    text-align: left;
  }
  .slide-caption.slide-topleft,
  .slide-caption.slide-bottomleft {
    left: 10%;
    right: initial;
    text-align: left;
  }
  .slide-caption.slidebottomleft,
  .slide-caption.slidebottomright {
    top: initial;
    bottom: 45.7142px;
  }
}
.slide-caption {
  text-align: left;
}

@media only screen and (min-width: 35.5em) and (max-width: 63.875em) {
  .slide-caption {
    position: absolute !important;
    display: inline-block;
    margin: 0;
  }
}
@media only screen and (min-width: 63.9375em) {
  .slide-caption {
    position: absolute !important;
    display: inline-block;
    margin: 0;
  }
}
/* #main_slider .owl-buttons{
	display: none;
}

@media only screen and (min-width:570px){
	#main_slider .owl-buttons{
		display: block;
	}
} */
/* pagination slider */
.owl-pagination {
  text-align: center;
  position: relative;
  display: block;
  bottom: 24px;
  transform: translate(0, -100%);
}

.owl-pagination .owl-page {
  display: inline-block;
  width: 8px;
  height: 8px;
  display: inline-block;
  padding: 0 12px 0;
  zoom: 1;
  vertical-align: bottom;
}

.owl-pagination .owl-page:first-child {
  padding-left: 0;
}

.owl-pagination .owl-page span {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #999;
}

.owl-pagination .owl-page.active span {
  background-color: #ffffff;
}

/* .owl-controls{
		margin-top: 3.6em;
	} */
.owl-buttons {
  display: none;
  /* display: block; */
  position: absolute;
  top: 50%;
  height: 86px;
  width: 100%;
  transform: translate(0, -50%);
  font-family: "pag-iconfont";
  color: #fff;
  font-size: 62px;
}

.owl-buttons .owl-prev,
.owl-buttons .owl-next {
  height: 64px;
  width: 64px;
  display: inline-block;
  text-align: center;
  /* background-color: #3e4146; */
  background-color: transparent;
}

.owl-buttons .owl-prev:hover,
.owl-buttons .owl-prev:focus,
.owl-buttons .owl-next:hover,
.owl-buttons .owl-next:focus {
  color: #d5001c;
  background-color: transparent;
}

.owl-buttons .owl-next {
  float: right;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.owl-buttons .owl-prev {
  float: left;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.owl-buttons .owl-next:after {
  content: "\e024";
  line-height: 64px;
}

.owl-buttons .owl-prev:after {
  content: "\e025";
  line-height: 64px;
}

.section-container {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fff;
  overflow: auto;
}

/* SECTION TITLE */
.section-title {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  font-family: "PorscheNext", Arial, Helvetica, sans-serif;
  font-weight: 300;
  line-height: normal;
  font-size: 26px;
  text-align: center;
  color: #000;
  width: 100%;
  z-index: 1;
  margin-top: 0;
  margin-bottom: 10px;
  background-color: inherit;
}

@media only screen and (min-width: 35.5em) and (max-width: 63.875em) {
  .section-title {
    font-size: 26px;
  }
}
@media only screen and (min-width: 63.9375em) {
  .section-title {
    font-size: 36px;
  }
}
.section-title span {
  background-color: inherit;
  display: inline-block;
  height: 100%;
  z-index: 1;
  padding-left: 1em;
  padding-right: 1em;
  background-color: #ffffff;
}

/* .section-title::before {
    content: '';
    border-top: 1px solid #ccc;
    font-size: 0;
    position: absolute;
    top: 49.5%;
    right: 0;
    left: 0;
    height: 1px;
    overflow: hidden;
    z-index: -1
}

@media only screen and (max-width:479px) {
    .section-title::before {
        display: none
    }
}*/
/* CAR BOX OLD */
/* .car-box{
	float:left;
	padding-top: 22%;
	padding-bottom: 11%;
	width: 48%;
	position: relative;
	margin-left: 0.51%;
}

.car-box.first-child{
	margin-left: 0.25%;
}

	.car-box__wrapper{
		position: absolute;
		bottom: 0;
		width: 100%;
		background-color: #fff;
		border: 1px solid #fff;
		padding-bottom: .625em;
	}

	.car-box__wrapper:hover:after,
	.car-box__wrapper:focus:after{
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: .3em;
		border-bottom: .25em solid #d5001c;
		border-radius: 0 0 .3125em .3125em;
		-webkit-border-radius: 0 0 .3125em .3125em;
		-moz-border-radius: 0 0 .3125em .3125em;
	}

	.car-box__wrapper:hover,
	.car-box__wrapper:focus{
		border: 1px solid #ccc;
		background-color: #fff;
		z-index: 1;
		cursor: pointer;
		border-radius: .3125em;
		-webkit-border-radius: .3125em;
		-moz-border-radius: .3125em;
	}

	.car-box__wrapper:hover figcaption a,
	.car-box__wrapper:focus figcaption a{
		color: #d5001c;
	}

	.car-box__wrapper:hover .car-links,
	.car-box__wrapper:focus .car-links{
		display: block;
	}

		.car-box__wrapper_links{
			max-width: 95.21%;
			margin: 0 auto;
			padding-top: 1.25em;
			padding-bottom: .6em;
		}

			.car-links{
				display: none;
			}

				.car-links li{
					font-size: .875em;
					padding-bottom: 1.08em;
					padding-left: 13%;
					text-align: left;
				}

					.car-links a{
						color: #1b1d1f;
						text-decoration: none;
					}

					.car-links a:hover,
					.car-links a:focus{
						color: #d5001c;
					}

					.car-links a:before{
						font-family: 'pag-iconfont';
						content: "\e00d";
						color: #d5001c;
						display: inline-block;
						height: 100%;
						vertical-align: top;
						margin-right: .3125em;
						margin-left: -1em;
						font-size: .8125em;
						padding-top: 0.3em;
					}



		.car-box figure{
			margin-bottom: .56em;
		}

		.car-box img{
			display: block;
			margin: 0 auto;
			width: 100%;
			margin-top: -.625em;
			margin-bottom: .125em;
		}
		.car-box figcaption{
			font-size: .875em;
			text-align: center;
		}

	.car-box.compare span{
		display: block;
		margin-bottom: .56em;
		padding-bottom: .6em;
		text-align: center;
	}

	.car-box.compare span:before{
		font-family: 'pag-iconfont';
		content: '\e035';
		font-size: 4.375em;
		color: #3a6b9b;
		display: block;
		margin-bottom: -0.2em;
	}

		.car-box.compare span a{
			font-size: 0.875em;
		}

	.car-box.compare .car-box__wrapper:hover{

		border: 1px solid #fff;
	}

		.car-box.compare .car-box__wrapper:hover a,
		.car-box.compare .car-box__wrapper:hover span:before{
			color: #d5001c;
		} */
/* MODEL CARD */
.model-card {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
  min-height: 1px;
  float: left;
}

.model-card__headline::before,
.model-card-info__headline::before,
.model-card-info__link::before {
  content: "\e01f";
  font-family: "pag-iconfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  color: #d5001c;
}

.model-card__info {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-transform: translateZ(0);
  font-size: 16px;
  color: #000;
}

.model-card__headline,
.model-card-info__headline {
  font-family: "PorscheNext", Arial, Helvetica, sans-serif;
  font-weight: 400;
}

/* size */
.model-card {
  width: calc(50% - 6px);
  margin-bottom: 3%;
}

.model-card:before {
  content: "";
  float: left;
  padding-bottom: 112.5%;
}

.model-card:after {
  content: "";
  display: table;
  clear: both;
}

.model-card:nth-child(2n) {
  margin-left: 12px;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .model-card {
    width: calc(50% - 6px);
    margin-left: 0;
  }
  .model-card:nth-child(2n) {
    margin-left: 12px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .model-card {
    width: calc(33.333% - 10px);
  }
  .model-card:not(:first-of-type),
  .model-card:nth-child(4) {
    margin-left: 0;
  }
  .model-card:nth-child(3n+2),
  .model-card:nth-child(3n+3) {
    margin-left: 12px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .model-card {
    width: calc(20% - 10px);
  }
  .model-card:not(:first-of-type) {
    margin-left: 12px;
  }
}
@media only screen and (min-width: 1200px) {
  .model-card {
    width: calc(20% - 10px);
  }
  .model-card:not(:first-of-type) {
    margin-left: 12px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .model-card {
    margin-bottom: 2%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .model-card {
    margin-bottom: 2%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .model-card {
    margin-bottom: 1%;
  }
}
@media only screen and (min-width: 1200px) {
  .model-card {
    margin-bottom: 1%;
  }
}
@media only screen and (min-width: 992px) {
  .model-card.card--hide-on-desktop {
    display: none !important;
  }
}
.model-card__link {
  display: block;
}

.model-card__headline {
  position: absolute;
  bottom: 25px;
  left: 25px;
  color: #fff;
  font-size: 24px;
}

.model-card__headline::before {
  color: #d5001c;
  vertical-align: middle;
}

.moder-card.card--is-hovered .model-card__headline {
  display: none;
}

.model-card__headline span {
  vertical-align: middle;
}

.model-card__image {
  width: 100%;
  display: block;
}

.model-card__info {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  padding: 25px 0 25px 25px;
  background-color: rgba(27, 29, 31, 0.5);
  transition: all 0.5s;
}

.model-card.card--is-hovered .model-card__info {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.24s;
}

.model-card-info__headline {
  color: #fff;
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
}

.model-card-info__headline::before {
  color: #d5001c;
  vertical-align: middle;
}

.model-card-info__headline:hover,
.model-card-info__headline:hover::before {
  color: #d5001c;
}

.model-card-info__headline span {
  vertical-align: middle;
}

.model-card-info__price {
  color: #fff;
  display: block;
}

.model-card-info__link-wrapper {
  position: absolute;
  bottom: 25px;
  left: 25px;
}

.model-card-info__link {
  display: block;
  color: #fff;
  padding-top: 20px;
  text-decoration: none;
  opacity: 0;
}

.model-card-info__link:hover {
  color: #d5001c;
}

.model-card-info__link::before {
  color: #d5001c;
  font-size: 12px;
  padding-right: 3px;
}

.model-card-info__link:nth-child(1) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.model-card-info__link:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.model-card-info__link:nth-child(3) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.model-card.card--is-hovered .model-card-info__link {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  -webkit-animation-duration: 0.24s;
  animation-duration: 0.24s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes slideUp {
  from {
    -webkit-transform: translate3d(0, 40%, 0);
    transform: translate3d(0, 40%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes slideUp {
  from {
    -webkit-transform: translate3d(0, 40%, 0);
    transform: translate3d(0, 40%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
/* BANNER */
.teaser-wrapper {
  display: block;
  /* margin-top: 1.5625em; */
  /* margin-bottom: 1.5625em */
}

.teaser-link {
  display: block;
  float: left;
  width: 100%;
  /* margin-bottom: 1em; */
}

.teaser-link:first-child {
  margin-left: 0;
}

.teaser-link figure figcaption {
  display: inline-block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 5px 20px;
  /* margin-left: 40%;
  	height: 65px;*/
}

.teaser-link figure figcaption:before {
  position: absolute;
  top: 9px;
  left: 0;
  /* top: 0.2em;
  	left: -1.3em; */
  font-size: 12px;
  display: block;
  color: #d5001c;
  background-color: #fff;
  line-height: 16px;
  font-family: "pag-iconfont";
  content: "\e00d";
}

.teaser-link figure .img-wrapper {
  /* width: 30.33333%; */
  display: block;
  width: 100%;
  position: relative;
  float: left;
}

.teaser-link figure .img-wrapper:after {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  content: "";
  background-color: #d5001c;
  height: 5px;
  margin-top: -5px;
  opacity: 0;
  filter: alpha(opacity=0);
}

.teaser-link figure .img-wrapper:hover:after,
.teaser-link figure .img-wrapper:focus:after {
  opacity: 1;
  filter: alpha(opacity=100);
}

.teaser-link figure .img-wrapper img {
  vertical-align: middle;
  width: 100%;
}

.teaser-slider {
  margin-left: -2px;
  margin-right: -2px;
}

.teaser-slider .owl-item {
  margin: 0;
}

@media only screen and (min-width: 769px) {
  .teaser-slider .owl-item {
    margin: 2px;
  }
}
.teaser-slider .teaser-link {
  width: 100%;
}

.teaser-slider .owl-pagination {
  top: 0;
  padding: 8px 0;
  transform: none;
}

.teaser-slider .owl-pagination .owl-page {
  padding: 0;
  height: 8px;
  width: 8px;
  margin: 6px 7px 6px;
  border-radius: 4px;
}

.teaser-slider .owl-pagination .owl-page span {
  display: block;
  background-color: rgba(27, 29, 31, 0.5);
}

.teaser-slider .owl-pagination .owl-page.active span {
  background-color: #1b1d1f;
}

/*---------------------------
---------------------------
MAIN - PAGES
---------------------------
---------------------------*/
#title_bar {
  background-color: #333;
  color: #fff;
  padding-left: 6.8125%;
  padding-right: 6.8125%;
  padding-top: 0.76922em;
  padding-bottom: 0.9em;
  font-family: "PorscheNext";
  font-weight: 300;
  margin-bottom: 1em;
}

#title_bar h1 {
  font-size: 1.625em;
}

#title_bar h2 {
  font-family: "PorscheNext", sans-serif;
  font-size: 0.875em;
  font-weight: 400;
}

.standard-content {
  /* max-width: 50em; */
  max-width: 100%;
}

.title {
  font-family: "PorscheNext";
  font-weight: 300;
  /* font-size: 1.625em;
  margin: 1em 0 0.923em 0; */
}

.middle-title {
  font-family: "PorscheNext";
  font-weight: 300;
  /*font-size: 1.125em;
  margin-top: 1.25em; */
  display: block;
}

p, .paragraph {
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400;
  margin: 16px 0;
}

/*thumbs-list*/
.thumbs-list {
  width: 100%;
  float: left;
}

.thumbs-list ul li {
  display: inline;
  width: 20%;
  padding-bottom: 1em;
  float: left;
}

.thumbs-list ul li div {
  width: 100%;
  float: left;
}

.thumbs-list ul li .thumb-title {
  position: relative;
  font-size: 0.875em;
  font-weight: normal;
}

.thumbs-list ul li .division-row {
  padding: 0;
}

.thumbs-list ul li .division-row div {
  padding-right: 1em;
}

.thumbs-list ul li .division-row div a {
  position: relative;
  bottom: 0;
  padding-left: 1em;
  font-weight: bold;
  float: left;
}

.thumbs-list ul li .division-row div a::before {
  position: absolute;
  font-family: "pag-iconfont";
  content: "\e00d";
  color: #d5001c;
  left: -0.25em;
}

.thumbs-list ul li .division-row img {
  width: 100%;
  max-width: 100%;
  margin: 0 0 0.5em 0;
  padding-right: 1em;
  float: left;
}

/*---------------------------
 max-width:801px
---------------------------*/
@media only screen and (max-width: 50.063em) {
  .thumbs-list ul li {
    width: 25%;
  }
  .thumbs-list ul li .division-row img {
    display: block;
  }
}
/*---------------------------
 max-width:321px
---------------------------*/
@media only screen and (max-width: 20.063em) {
  .thumbs-list ul li {
    width: 100%;
    margin-bottom: 1em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #CCC;
  }
  .thumbs-list ul li .division-row img {
    display: block;
    padding-right: 0;
  }
  .thumbs-list ul li .division-row div p {
    margin: 1em 0;
  }
  .thumbs-list ul li .division-row div {
    padding-right: 0;
  }
}
.division-row li {
  font-size: 0.875em;
}

.division-row {
  margin-top: 8px;
  margin-bottom: 24px;
  clear: both;
}

/* .dotted{
	border-bottom: 1px dashed #ccc;
} */
.division-row .left-cn,
.division-row .right-cn {
  float: left;
}

.division-row .right-cn {
  max-width: 440px;
  margin-left: 16px;
}

.division-row img {
  float: left;
  margin-right: 16px;
  /*margin-bottom: 1em;
  margin-top: 5px; */
  max-width: 151px;
  display: none;
}

.division-row.catalog img {
  max-width: none;
}

.division-row p {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
}

.division-row .news-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  display: block;
}

.division-row .news-title:before {
  position: absolute;
  font-family: "pag-iconfont";
  content: "\e01f";
  color: #d5001c;
  left: -20px;
  top: 3px;
  line-height: 16px;
}

.division-row .news-list {
  color: #666;
  font-size: 0.875em;
}

.division-row .news-list li a {
  color: #666;
}

.division-row .news-list li a:before {
  font-family: "pag-iconfont";
  content: "\e00d";
}

.division-row .news-list li a:hover,
.division-row .news-list li a:focus {
  color: #d5001c;
}

/*Page Submenu*/
.page-menu {
  text-align: center;
}

.page-menu ul {
  display: inline-block;
  background-color: #3e4146;
  text-align: center;
  /* border-radius: 3px;
  webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px; */
}

.page-menu ul li {
  display: inline-block;
}

.page-menu ul li > a {
  position: relative;
  color: #fff;
  display: inline-block;
  padding: 1.21429em 1.78571em;
  line-height: 0.875em;
  font-size: 16px;
}

.page-menu ul li > a > span:before {
  position: absolute;
  content: " ";
  height: 1em;
  width: 0;
  left: 0;
  border-left: 1px solid #fff;
}

.page-menu ul li:first-child > a > span:before {
  border: none;
}

.page-menu ul li > a:hover > span:after,
.page-menu ul li > a:focus > span:after,
.page-menu ul li > a.active > span:after {
  border-bottom: 4px solid #d5001c;
  content: " ";
  width: 70%;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 15%;
}

/*Come raggiungerci*/
#map-canvas,
#main_picture {
  position: relative;
  width: 100%;
  overflow: hidden;
  max-height: 737px;
  /* max-height: 510px; */
  /* margin-bottom: 2em; */
}

#map-canvas {
  height: 737px;
}

#main_picture img {
  width: 100%;
  display: block;
}

#map-canvas img {
  max-width: none !important;
}

/*News Detail*/
table.base {
  font-size: 0.875em;
  border-collapse: collapse;
  padding: 0;
  width: 100%;
  margin: 1em 0;
}

table.base td {
  text-align: left;
  vertical-align: top;
  margin: 0;
  padding: 8px;
  padding-bottom: 24px;
  margin-bottom: 0;
  color: #000000;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

table.base a {
  display: block;
  /* background-image: url('../images/arrow-gray.gif');
  background-position: 2px 7px;
  background-repeat: no-repeat; */
  margin-right: 20px;
  padding-left: 16px;
  text-decoration: none;
  color: #000000;
}

table.base a::before {
  content: "\e01f";
  font-family: "pag-iconfont";
  line-height: 16px;
  position: relative;
  display: inline-block;
  padding-right: 10px;
  left: 0;
  color: #d5001c;
}

/* table.base a:hover,
	table.base a:focus{
		background-image: url('../images/arrow-red.gif');
		color: #d5001c;
	} */
/*Gallery*/
.gallery-content {
  max-width: 100%;
}

.gallery-content ul {
  margin-left: -2%;
}

.gallery-content li {
  /*float: left;*/
  display: inline-block;
  margin-left: 2%;
  width: 47%;
}

.gallery-content li a {
  display: block;
  width: 100%;
}

.gallery-content li a:hover img,
.gallery-content li a:focus img {
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.gallery-content li a img {
  width: 100%;
  margin-bottom: 1em;
}

/*Download*/
.download-list .button {
  width: 70%;
  margin: 1.143em auto;
  display: block;
}

/*Auto*/
.car-measure {
  position: relative;
  width: 95%;
  text-align: center;
  margin: 0 auto;
}

/*
.car-measure img{
	margin-bottom: -36px;
}*/
.vertical-line-text {
  position: absolute;
  right: 0;
  margin-top: 8%;
  top: 0;
}

.vertical-line {
  display: block;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  top: 0;
  margin-top: 14%;
  overflow: hidden;
  right: 0;
  height: 37%;
  width: 9px;
}

.vertical-line:after {
  content: "";
  display: block;
  border-left: 1px solid #ccc;
  height: 100%;
  margin: 0 auto;
  width: 1px;
}

.horizontal-line-large {
  display: block;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  width: 90%;
  margin: 0 auto;
  height: 10px;
}

.horizontal-line-large:after {
  content: "";
  display: block;
  border-bottom: 1px solid #ccc;
  margin-top: 0;
  height: 6px;
}

.horizontal-line-small {
  display: block;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  margin: 0 auto;
  width: 53%;
  height: 10px;
}

.horizontal-line-small:after {
  content: "";
  display: block;
  border-bottom: 1px solid #ccc;
  margin-top: 0;
  height: 6px;
}

.horizontal-line-small-text, .horizontal-line-large-text {
  position: relative;
  top: 2em;
}

.car-details {
  margin: 3rem 0;
}

.tech-spec {
  line-height: 2em;
  font-size: 16px;
  width: 100%;
}

.tech-spec tr {
  border-bottom: 1px solid #1b1d1f;
}

.tech-spec tbody tr {
  border-bottom: 1px solid #ccc;
}

.tech-spec-legend {
  font-weight: bold;
}

.tech-spec-data {
  text-align: right;
}

.car-equip ul {
  list-style: disc inside none;
  padding: 0.625em 0;
  font-size: 1em;
}

/*Porsche Selection/Tequipment*/
.selection-element {
  margin-top: 24px;
  margin-bottom: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.selection-element:first-child {
  margin-top: 3em;
}

.selection-element-img img {
  max-width: 100%;
}

.art-code {
  font-size: 0.75em;
  font-weight: bold;
}

.selection-element-desc {
  max-width: 450px;
  margin-left: 2%;
}

.prices span {
  display: block;
}

/*Porsche Promozioni*/
.validita {
  color: #999;
  font-size: 0.75em;
  font-weight: bold;
}

.gray {
  color: #999;
}

/*Pagine Contatti/Prenotazioni*/
#altro_modello_prova, #altro_titolo_accademico, #ditta {
  display: none;
}

/*Iframe page*/
iframe {
  width: 750px;
  height: 1200px;
  display: block;
  border: 0;
  overflow: hidden;
  float: none;
  margin: 0 auto;
}

.iframe-image {
  margin: 30px auto;
  display: block;
  max-width: 677px;
}

/*---------------------------
---------------------------
FEATURES SLIDE
---------------------------
---------------------------*/
.features-slide {
  display: block;
  overflow: hidden;
}

.features-slide__left,
.features-slide__right {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
  min-height: 1px;
}

.features-slide__left {
  float: left;
  width: 100%;
}

@media only screen and (min-width: 46.875em) and (max-width: 55em) {
  .features-slide__left {
    width: 48%;
  }
}
@media only screen and (min-width: 55.0625em) and (max-width: 63.875em) {
  .features-slide__left {
    width: 39.66667%;
  }
}
@media only screen and (min-width: 63.9375em) {
  .features-slide__left {
    width: 40.66667%;
  }
}
.features-slide__right {
  float: left;
  width: 100%;
}

@media only screen and (max-width: 46.9375em) {
  .features-slide__right {
    margin-top: 24px;
  }
}
@media only screen and (min-width: 46.875em) and (max-width: 55em) {
  .features-slide__right {
    width: 48%;
    left: 0;
  }
}
@media only screen and (min-width: 55.0625em) and (max-width: 63.875em) {
  .features-slide__right {
    width: 48%;
    left: 4.16667%;
  }
}
@media only screen and (min-width: 63.9375em) {
  .features-slide__right {
    width: 40.66667%;
    left: 8.33333%;
  }
}
.features-slide .button {
  margin-top: 10px;
  margin-bottom: 0;
}

.features-slide_padding_bottom {
  padding-bottom: 24px;
}

.section-negative {
  background-color: #000;
  padding-top: 40px;
  padding-bottom: 48px;
}

.section-negative .section-title {
  color: #ffffff;
}

/*---------------------------
---------------------------
BOTTOM CONTEXT MENU
---------------------------
---------------------------*/
#context-menu {
  background-color: #fff;
  padding-top: 30px;
}

.context-menu-title {
  position: relative;
  font-family: "PorscheNext";
  font-weight: 300;
  z-index: 1;
  text-align: center;
  margin-bottom: 15px;
}

.context-menu-title span {
  background-color: #fff;
  padding-left: 1em;
  padding-right: 1em;
  z-index: 1;
  height: 100%;
}

/*.context-menu-title span:before{
			content: '';
			display: inline-block;
			border-top: 1px solid #ccc;
			position: absolute;
			top: 49.5%;
			right: 0;
			left: 0;
			height: 1px;
			overflow: hidden;
			z-index: -1;
		}*/
#context-menu ul {
  margin-top: 0;
  margin-bottom: 40px;
  text-align: left;
}

#context-menu li {
  display: block;
}

#context-menu li a {
  line-height: 50px;
  font-size: 16px;
}

#context-menu a {
  line-height: 30px;
  display: inline-block;
  text-decoration: none;
  color: #000;
  display: block;
}

@media only screen and (min-width: 35.5em) {
  #context-menu ul {
    text-align: center;
  }
  #context-menu li {
    display: inline-block;
  }
}
@media only screen and (min-width: 35.5em) and (max-width: 63.875em) {
  #context-menu a {
    padding-right: 14px;
    display: inline-block;
    line-height: 30px;
  }
  #context-menu a::before {
    border-left: 1px solid #c8cacb;
    padding-right: 14px;
    content: "";
    height: 16px;
  }
}
@media only screen and (min-width: 63.9375em) {
  #context-menu a {
    padding-right: 14px;
    display: inline-block;
    line-height: 30px;
  }
  #context-menu a::before {
    border-left: 1px solid #c8cacb;
    padding-right: 14px;
    content: "";
    height: 16px;
  }
}
#context-menu a span {
  line-height: normal;
  display: inline-block;
}

.no-touch #context-menu a:hover {
  color: #d5001c;
}

@media only screen and (min-width: 35.5em) and (max-width: 63.875em) {
  #context-menu li:first-child a {
    padding-left: 0;
  }
}
@media only screen and (min-width: 63.9375em) {
  #context-menu li:first-child a {
    padding-left: 0;
  }
}
#context-menu li:first-child a::before {
  padding-right: 0;
  border-left: none;
}

/*---------------------------
---------------------------
FOOTER
---------------------------
---------------------------*/
.footer-menu {
  padding-bottom: 2.2em;
  margin-top: 10px;
}

.footer-menu__column {
  display: block;
  width: 100%;
  border-bottom: 1px solid #c8cacb;
  position: relative;
}

@media only screen and (min-width: 569px) {
  .footer-menu__column {
    width: 50%;
    float: left;
    border-bottom: none;
  }
  .footer-menu__column ul {
    display: block;
  }
}
@media only screen and (min-width: 1024px) {
  .footer-menu__column {
    float: left;
  }
}
.footer-menu__column h4:after {
  font-family: "pag-iconfont";
  content: "\e021";
  position: absolute;
  right: 0;
  color: #000000;
}

.footer-menu__column.open h4:after {
  content: "\e01e";
}

@media only screen and (min-width: 569px) {
  .footer-menu__column h4:after {
    display: none;
  }
}
.footer-menu__column h4 {
  font-family: "PorscheNext", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  cursor: pointer;
  color: inherit;
  /* border-bottom: 1px solid #c8cacb; */
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .footer-menu__column h4 {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu__column h4 {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-menu__column h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .footer-menu__column h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
  }
}
@media only screen and (min-width: 35.5em) and (max-width: 63.875em) {
  .footer-menu__column h4 {
    color: #959899;
    border-bottom: none;
    cursor: auto;
  }
}
@media only screen and (min-width: 63.9375em) {
  .footer-menu__column h4 {
    color: #959899;
    border-bottom: none;
    cursor: auto;
  }
}
.footer-menu__column ul {
  font-size: 0.875em;
  line-height: 1.8em;
  display: none;
}

.footer-menu__column ul li a {
  display: inline-block;
  text-decoration: none;
  color: #000;
  font-size: 16px;
  line-height: normal;
}

.footer-menu__column ul li > a:first-child:last-child {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media only screen and (min-width: 63.9375em) {
  .footer-menu__column ul li a {
    line-height: 1.92857em;
  }
}
#footer-menu__social-links {
  margin-left: 0;
  color: #999;
}

#footer-menu__social-links h4 {
  font-family: "PorscheNext", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-weight: normal;
  font-size: 1.125em;
  margin-bottom: 0;
}

#footer-menu__social-links li {
  float: left;
}

#footer-menu__social-links a {
  display: block;
  /* -webkit-border-radius: 3px;
  			-moz-border-radius: 3px;
  			-ms-border-radius: 3px;
  			-o-border-radius: 3px;
  			border-radius: 3px; */
  width: 50px;
  height: 50px;
  margin-right: 4px;
  margin-bottom: 4px;
  background-color: #3e4146;
  font-family: "pag-iconfont";
  font-style: normal;
  color: #fff;
}

#footer-menu__social-links a.fb:hover,
#footer-menu__social-links a.fb:focus {
  background-color: #2753A7;
}

#footer-menu__social-links a.gp:hover,
#footer-menu__social-links a.gp:focus {
  background-color: #DA4237;
}

#footer-menu__social-links a.yt:hover,
#footer-menu__social-links a.yt:focus {
  background-color: #C91602;
}

#footer-menu__social-links a.tw:hover,
#footer-menu__social-links a.tw:focus {
  background-color: #1DC8E9;
}

#footer-menu__social-links a.pt:hover,
#footer-menu__social-links a.pt:focus {
  background-color: #CD242B;
}

#footer-menu__social-links a.in:hover,
#footer-menu__social-links a.in:focus {
  background-color: #3f729b;
}

#footer-menu__social-links a.ln:hover,
#footer-menu__social-links a.ln:focus {
  background-color: #0274b3;
}

#footer-menu__social-links a.fb::before,
#footer-menu__social-links a.gp::before,
#footer-menu__social-links a.yt::before,
#footer-menu__social-links a.tw::before,
#footer-menu__social-links a.pt::before,
#footer-menu__social-links a.in::before,
#footer-menu__social-links a.ln::before {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-size: 28px;
}

#footer-menu__social-links a.fb::before {
  content: "\e009";
}

#footer-menu__social-links a.gp::before {
  content: "\e008";
}

#footer-menu__social-links a.yt::before {
  content: "\f000";
}

#footer-menu__social-links a.tw::before {
  content: "\e001";
}

#footer-menu__social-links a.pt::before {
  content: "\e006";
}

#footer-menu__social-links a.in::before {
  content: "\e601";
}

#footer-menu__social-links a.ln::before {
  content: "\e602";
}

.footer-bottom {
  background-color: #0e0e12;
  color: #fff;
  margin-top: 4.375em;
  padding-top: 3.9em;
  padding-bottom: 4.375em;
}

.footer-bottom.footer-bottom--smaller {
  padding-top: 30px;
  padding-bottom: 30px;
}

.footer-legals {
  font-size: 0.875em;
  line-height: 1.5em;
}

.footer-legals p {
  margin: 1.4em 0;
}

.footer-legals a {
  color: #fff;
  text-decoration: underline;
}

.footer-legals a:hover,
.footer-legals a:focus {
  text-decoration: underline;
}

.footer-assistance {
  position: relative;
  font-family: "PorscheNext", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.125em;
  line-height: 1;
  font-weight: normal;
  margin: 0 0 60px 0;
}

.footer-assistance__title {
  font-weight: 300;
}

.footer-assistance__number {
  font-family: "PorscheNext", Arial, Helvetica, sans-serif;
  font-weight: 400;
  display: inline-block;
  margin-top: 10px;
  color: #fff;
  font-size: 0.8em;
  font-weight: 100;
  line-height: 1.2em;
}

.footer-assistance__number a {
  color: #ffffff;
}

.js-phone {
  cursor: pointer;
}

.js-phone.disabled,
.js-phone.disabled:hover {
  cursor: default;
  color: inherit;
  text-decoration: none !important;
  opacity: 1;
}

.footer-note {
  position: relative;
  display: block;
  clear: both;
}

/*---------------------------
 min-width:569px
---------------------------*/
@media only screen and (min-width: 35.563em) {
  .slide > a, .slide .cta {
    position: absolute;
    /* left: 4.7%; */
    /* bottom: .95em; */
    color: #fff;
    text-align: left;
    /* height: 2em; */
    /* font-size: 1.375em; */
  }
  .slide > a.white {
    color: #fff;
  }
  .slide > a.black {
    color: #000;
  }
  /* .slide>a.slide-bottomleft, .slide .cta.slide-bottomleft{
  	left: 7.5%;
  	right: initial;
  	top: initial;
  	bottom: 2.85714em;
  }

  .slide>a.slide-bottomright, .slide .cta.slide-bottomright{
  	right: 7.5%;
  	left: initial;
  	top: initial;
  	bottom: 2.85714em;
  }

  .slide>a.slide-topleft, .slide .cta.slide-topleft{
  	left: 7.5%;
  	right: initial;
  	top: 1.5em;
         top: 2.85714em;
         bottom: initial;
   }*/
}
.slide > a.slide-topright, .slide .cta.slide-topright {
  right: 7.5%;
  left: initial;
  /* top: 1.5em; */
  top: 2.85714em;
  bottom: initial;
}

.slide > a:before, .slide .cta:before {
  float: left;
  /* font-size: 0.67em; */
  margin-top: 0.1em;
  margin-right: 0.25em;
}

/*.slide h2{
				float: right;
			}

		 .slide h2:before{
			font-size: .682em;
		}

			.slide h2 span{
				font-family: 'PorscheNext', Arial, Helvetica, sans-serif;
				margin-left: 0;
				font-size: .636em;
			} */
.owl-pagination .owl-page span {
  background-color: #ccc;
}

.owl-pagination .owl-page.active span {
  background-color: #fff;
}

#main_slider:hover .owl-buttons,
#main_slider:focus .owl-buttons {
  display: block;
}

.owl-controls {
  margin-top: 0;
}

/* .car-box{
		padding-top: 14%;
		width: 32.8%;
	 }*/
/*#context-menu ul{
		text-align: center;
	}
		#context-menu li{
			display: inline-block;
			margin-right: -4px;
		}
			#context-menu li a{
				padding-right: .875em;
				line-height: 1em;
			}

			#context-menu li a:before{
				content:'';
				padding-right: .875em;
				border-left: 1px solid #ccc;
			}

			#context-menu li:first-child a{

			}

			#context-menu li:first-child a:before{
				border: 0;
				padding-right: 0;
			}*/
.teaser-row {
  width: 100%;
  float: left;
}

.teaser-link {
  display: block;
  float: left;
  width: 49.7%;
  margin-left: 0.3%;
  margin-bottom: 0;
}

.teaser-link figure .img-wrapper {
  width: 100%;
  float: none;
}

.teaser-link figure figcaption {
  /* margin: 0 10px; */
  padding: 5px 20px;
}

/*.teaser-link figure figcaption:before{
			top: 14px;
			left: 0;
		} */
.footer-menu {
  border-top: 1px solid #CCCCCC;
}

/* .footer-menu__column{
			width: 50%;
			min-height: 9em;
			margin-top: 2.15em;
			line-height: 1em;
			border: none;
		} */
.footer-menu__column:nth-child(3) {
  clear: both;
}

.footer-menu__column ul {
  line-height: 1.9em;
}

.footer-menu__column ul li a {
  line-height: normal;
}

/* .footer-menu__column h4{
				color: #999;
				margin-bottom: .3em;
				cursor: default;
			}

			.footer-menu__column h4:after{
				content: '';
			}*/
.footer-menu__column ul {
  display: block;
}

/* #footer-menu__social-links{
			margin-left: 50%;
		} */
.gallery-content ul {
  margin-left: -1em;
}

.gallery-content li {
  width: auto;
  margin-left: 1em;
}

.gallery-content li a {
  width: 208px;
}

.selection-element-img img {
  max-width: 450px;
}

/*---------------------------
 min-width:1023px
---------------------------*/
@media only screen and (min-width: 63.9375em) {
  /* header{
  	padding-left: 6.8125%;
  	padding-right: 6.8125%;
  }

  .slide>a.slide-topleft, .slide .cta.slide-topleft{
  	left: 7.5%;
  	top: 1.5em;
  }

  .slide>a.slide-topright, .slide .cta.slide-topright{
  	right: 7.5%;
  	top: 1.5em;
  }*/
  #sidenav {
    top: 231px;
    right: auto;
    bottom: auto;
  }
  #sidenav ul li.down {
    display: list-item;
  }
  #sidenav ul li.back:hover:after,
  #sidenav ul li.back:focus:after {
    right: -1px;
  }
  ul#breadcrumb {
    left: 41px;
    right: auto;
  }
}
/*---------------------------
 min-width:768px
---------------------------*/
@media only screen and (min-width: 48em) {
  /* .car-box{
  	padding-top: 6.6%;
  	width: 24%;
  } */
  .footer-legals {
    float: left;
  }
  .footer-assistance {
    float: right;
  }
  .footer-legals {
    width: 75%;
  }
  .footer-assistance {
    width: 25%;
  }
}
/*---------------------------
 min-width:801px
---------------------------*/
/*---------------------------
 min-width:821px
---------------------------*/
@media only screen and (min-width: 51.313em) {
  .division-row img {
    display: block;
  }
  .half-column {
    width: 49%;
  }
  .gr-column-large {
    width: 60.8%;
  }
  .gr-column-small {
    width: 37.2%;
  }
  .download-list .button {
    margin: 1.143em 0;
  }
}
/*---------------------------
 min-width:1023px
---------------------------*/
@media only screen and (min-width: 63.9375em) {
  .teaser-row {
    width: 50%;
    float: left;
  }
  .car-box-container {
    text-align: center;
  }
  /* .car-box{
  	width: 15.66667%;
  	float:none;
  	display: inline-block;
  	padding-top: 1%;
  } */
  .car-box.compare {
    display: none;
  }
  .footer-menu__column {
    width: 25%;
  }
  .footer-menu__column:nth-child(3) {
    clear: none;
  }
  #footer-menu__social-links {
    margin-left: 75%;
  }
}
/*---------------------------
 min-width:1200px
---------------------------*/
/* @media only screen and (min-width:75em){
	.tools-menu{
		right: 7.1%;
	}

	.tools-menu__container{
		right: 7.1%;
	}

} */
/*---------------------------
 min-width:1300px
---------------------------*/
@media only screen and (min-width: 81.25em) {
  iframe {
    /*width: 670px;*/
    width: 750px;
    height: 1200px;
    display: block;
    border: 0;
    overflow: hidden;
    float: left;
  }
  .iframe-image {
    margin-top: 120px;
    margin-bottom: 30px;
    margin-left: 696px;
    position: relative;
  }
}
/* Portrait and Landscape */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  #main_slider:hover .owl-buttons,
  #main_slider:focus .owl-buttons {
    display: none !important;
  }
  .slide-caption {
    padding-top: 0;
  }
}
/* Table Intro*/
table.base_2 {
  font-size: 16px;
  border-collapse: collapse;
  padding: 0;
  width: 100% !important;
  margin: 1em 0;
  line-height: 2em;
}

table.base_2 tr {
  border-bottom: 1px solid #1b1d1f;
}

table.base_2 tr th {
  text-align: left;
}

table.base_2 tbody tr {
  border-bottom: 1px solid #ccc;
}

table.base_2 td {
  text-align: left;
  vertical-align: top;
  margin: 0;
  padding: 10px 10px 10px 0;
  color: #666;
  font-weight: normal;
  margin-bottom: 0;
  line-height: 20px;
  height: auto !important;
  width: auto !important;
}

table.base_2 a {
  display: block;
  margin: 0 0 0.6em 0 !important;
  text-decoration: none;
  color: #666;
}

table.base_2 a:hover,
table.base_2 a:focus {
  color: #d5001c;
}

@media only screen and (max-width: 35.563em) {
  /* Force table to not be like tables anymore */
  table.base_2.responsive,
  table.base_2.responsive thead,
  table.base_2.responsive tbody,
  table.base_2.responsive th,
  table.base_2.responsive td,
  table.base_2.responsive tr {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  table.base_2.responsive thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  table.base_2.responsive tr {
    border-bottom: 1px solid #1b1d1f !important;
    padding: 10px 0;
  }
  table.base_2.responsive td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }
  table.base_2.responsive td:last-child {
    border-bottom: none;
  }
  table.base_2.responsive td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }
  /* Label the data */
  table.base_2.responsive td:before {
    content: attr(data-title);
    white-space: inherit;
    font-size: 0.8em;
  }
  table.base_2.responsive .link.block {
    float: none;
    margin: 0 0 0 0 !important;
  }
}
/*--------------------------
AREA ORDINI
--------------------------*/
.grid {
  margin-right: -22px;
  margin-left: -22px;
}

.grid:before,
.grid:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.grid:after {
  clear: both;
}

.grid {
  *zoom: 1;
}

.grid-sm-1 {
  width: 8.33%;
}

.grid-sm-2 {
  width: 16.66666667%;
}

.grid-sm-3 {
  width: 25%;
}

.grid-sm-4 {
  width: 33.33333333%;
}

.grid-sm-5 {
  width: 41.66666667%;
}

.grid-sm-6 {
  width: 50%;
}

.grid-sm-7 {
  width: 58.33333333%;
}

.grid-sm-8 {
  width: 66.66666667%;
}

.grid-sm-9 {
  width: 75%;
}

.grid-sm-10 {
  width: 83.33333333%;
}

.grid-sm-11 {
  width: 91.66666667%;
}

.grid-sm-12 {
  width: 100%;
}

@media only screen and (min-width: 35.563rem) { /*569px*/
  .grid-md-1 {
    width: 8.33%;
  }
  .grid-md-2 {
    width: 16.66666667%;
  }
  .grid-md-3 {
    width: 25%;
  }
  .grid-md-4 {
    width: 33.33333333%;
  }
  .grid-md-5 {
    width: 41.66666667%;
  }
  .grid-md-6 {
    width: 50%;
  }
  .grid-md-7 {
    width: 58.33333333%;
  }
  .grid-md-8 {
    width: 66.66666667%;
  }
  .grid-md-9 {
    width: 75%;
  }
  .grid-md-10 {
    width: 83.33333333%;
  }
  .grid-md-11 {
    width: 91.66666667%;
  }
  .grid-md-12 {
    width: 100%;
  }
}
@media only screen and (min-width: 63.9375rem) { /*1023px*/
  .grid-lg-1 {
    width: 8.33%;
  }
  .grid-lg-2 {
    width: 16.66666667%;
  }
  .grid-lg-3 {
    width: 25%;
  }
  .grid-lg-4 {
    width: 33.33333333%;
  }
  .grid-lg-5 {
    width: 41.66666667%;
  }
  .grid-lg-6 {
    width: 50%;
  }
  .grid-lg-7 {
    width: 58.33333333%;
  }
  .grid-lg-8 {
    width: 66.66666667%;
  }
  .grid-lg-9 {
    width: 75%;
  }
  .grid-lg-10 {
    width: 83.33333333%;
  }
  .grid-lg-11 {
    width: 91.66666667%;
  }
  .grid-lg-12 {
    width: 100%;
  }
}
.grid-sm-1,
.grid-sm-2,
.grid-sm-3,
.grid-sm-4,
.grid-sm-5,
.grid-sm-6,
.grid-sm-7,
.grid-sm-8,
.grid-sm-9,
.grid-sm-10,
.grid-sm-11,
.grid-sm-12,
.grid-md-1,
.grid-md-2,
.grid-md-3,
.grid-md-4,
.grid-md-5,
.grid-md-6,
.grid-md-7,
.grid-md-8,
.grid-md-9,
.grid-md-10,
.grid-md-11,
.grid-md-12,
.grid-lg-1,
.grid-lg-2,
.grid-lg-3,
.grid-lg-4,
.grid-lg-5,
.grid-lg-6,
.grid-lg-7,
.grid-lg-8,
.grid-lg-9,
.grid-lg-10,
.grid-lg-11,
.grid-lg-12 {
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 22px;
  padding-left: 22px;
}

@media only screen and (min-width: 63.9375rem) { /*1023px*/
  .offgrid-right--large {
    position: absolute;
    right: -10.55%;
  }
  .offgrid-left--large {
    position: absolute;
    left: -10.55%;
  }
}
.standard-content--spaced {
  margin: 4em 0;
}

.area-ordini-box {
  /*background-color: #F2F2F2;*/
  border: 1px solid #dbdbdb;
  box-shadow: 0 2px 3px #dbdbdb;
  padding: 30px;
  margin: 0;
}

.area-ordini-box .middle-title {
  margin: 0 0 15px 0;
}

.area-ordini-box .link {
  margin: 0;
  font-size: 13px;
}

.ordini-alert {
  color: #d5001c;
}

.ordini-alert--date::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url(../images/alert-date.html) 0 0 no-repeat;
  -moz-background-size: contain;
  -ms-background-size: contain;
  -o-background-size: contain;
  -webkit-background-size: contain;
  background-size: contain;
  vertical-align: text-top;
  margin-right: 3px;
}

.area-ordini-box .basket-title {
  margin-bottom: 0;
}

.area-ordini-box .basket-title + p {
  margin-top: 0;
}

.area-ordini-box .basket-title::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../images/shopping.html) 0 0 no-repeat;
  -moz-background-size: contain;
  -ms-background-size: contain;
  -o-background-size: contain;
  -webkit-background-size: contain;
  background-size: contain;
  margin-right: 10px;
}

.link-basket::after {
  content: "";
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url(../images/shopping.html) 0 0 no-repeat;
  -moz-background-size: contain;
  -ms-background-size: contain;
  -o-background-size: contain;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: 10px;
}

.area-ordini-box ul li {
  font-size: 11px;
  padding: 7px 0;
}

.area-ordini-box ul li:not(:last-child) {
  border-bottom: 1px dashed #ccc;
}

.area-ordini-box .total {
  text-align: right;
}

.ordini-table__input {
  margin-top: 0;
  padding-left: 10px;
  padding-right: 0;
  padding-top: 7px;
  padding-bottom: 7px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 0.875em;
  width: 100%;
  max-width: 70px;
  margin-bottom: 1.5em;
  outline: 0 !important;
  border: 1px solid #dfdfdf;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  vertical-align: initial;
  margin-right: 10px;
}

.submit-button {
  position: relative;
  display: inline-block;
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 0.71429em;
  padding-bottom: 0.71429em;
  background-color: #900;
  border: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  color: #fff;
  font-size: 0.875em;
  line-height: 1em;
  margin: 2.5em auto;
}

.submit-button:hover,
.submit-button:focus {
  color: #fff;
  background-color: #960014;
}

.submit-button:after,
.submit-button--grey:after {
  font-family: "pag-iconfont";
  color: #fff;
  content: "\e024";
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  position: relative;
  display: inline-block;
  padding-left: 5px;
  float: right;
  webkit-transition: margin 0.2s linear;
  -moz-transition: margin 0.2s linear;
  -o-transition: margin 0.2s linear;
  transition: margin 0.2s linear;
}

.submit-button:hover:after {
  margin-right: -2px;
  margin-left: 2px;
}

.print-button:after,
.print-button--grey:after {
  font-family: "pag-iconfont";
  color: #fff;
  content: "\e618";
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  position: relative;
  display: inline-block;
  padding-left: 5px;
  float: right;
  webkit-transition: margin 0.2s linear;
  -moz-transition: margin 0.2s linear;
  -o-transition: margin 0.2s linear;
  transition: margin 0.2s linear;
}

.text-right {
  text-align: right;
}

.button-row .button:not(:last-child) {
  margin-right: 20px;
}

@media only screen and (max-width: 35.563em) {
  .text-right {
    text-align: left;
  }
  .button-row .button {
    margin-top: 0;
  }
}
table.base_2 a.expander-trigger {
  margin: 0 !important;
  font-size: 12px;
}

table.base_2 .expander-trigger:after {
  font-family: "pag-iconfont";
  content: "\e004";
  font-size: 10px;
  position: relative;
  display: inline-block;
  padding-left: 5px;
  webkit-transition: margin 0.2s linear;
  -moz-transition: margin 0.2s linear;
  -o-transition: margin 0.2s linear;
  transition: margin 0.2s linear;
}

table.base_2 .expander-trigger.active:after {
  font-family: "pag-iconfont";
  content: "\e02d";
}

/*Pretty Embed*/
#main_picture .prettyembed {
  max-height: 612px;
  text-align: center;
  background: #000;
}

#main_picture .prettyembed img {
  max-height: 612px;
  width: inherit;
  display: inline-block;
  margin: 0 auto;
}

#main_picture .fluid-width-video-wrapper iframe,
#main_picture .fluid-width-video-wrapper object,
#main_picture .fluid-width-video-wrapper embed {
  max-height: 612px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  max-width: 960px;
  height: 100%;
}

/* conosciamoci meglio */
#main_slider.conosciamoci-meglio__top {
  margin-top: 0;
  margin-bottom: 0;
  display: block;
}

#main_slider.conosciamoci-meglio__top img {
  display: block;
}

#title_bar.conosciamoci-meglio__titlebar {
  background-color: #f2f2f2;
  padding-top: 1.25em;
  padding-bottom: 0.625em;
  color: #000;
  font-size: 0.875em;
}

#conosciamoci-meglio-form {
  position: relative;
}

#conosciamoci-meglio-form fieldset:first-of-type {
  margin-top: 0em;
}

@media only screen and (min-width: 35.563em) {
  #conosciamoci-meglio-form {
    margin-top: -6.875em;
  }
}
#conosciamoci-meglio-form .form-header {
  width: 100%;
  background-color: #960014;
  position: relative;
  display: inline-block;
  color: #fff;
  font-family: "PorscheNext", Arial, Helvetica, sans-serif;
  font-weight: 400;
  padding: 1em;
  font-size: 20px;
}

#conosciamoci-meglio-form .form-header:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(204, 0, 0, 0);
  border-top-color: #960014;
  border-width: 20px;
  margin-left: -20px;
}

#conosciamoci-meglio-form .form-header span {
  font-size: 1.1em;
}

@media only screen and (min-width: 35.563em) {
  #conosciamoci-meglio-form .form-header span {
    font-size: 1.25em;
  }
}
@media only screen and (min-width: 82.5em) {
  #conosciamoci-meglio-form .form-header span {
    font-size: 2em;
  }
}
#conosciamoci-meglio-form #datepicker {
  background: url(../images/calendar-ico.html) #ffffff no-repeat 96% 50%;
}

.form-radio-group {
  position: relative;
  display: block;
  list-style: none;
  margin: 0 0 20px 0;
}

.form-radio-group > li {
  display: block;
  position: relative;
}

.form-radio-group .form-column {
  display: inline-block;
  float: none;
  width: auto;
}

@media only screen and (min-width: 35.563em) {
  .form-radio-group > li {
    display: inline-block;
    padding-right: 30px;
  }
}
/***************************************
************ LANDING EVENTI ************
***************************************/
#main_picture.eventi-main-picture {
  max-height: inherit;
}

/* form */
.eventi-form {
  position: relative;
}

@media only screen and (min-width: 35.563em) {
  .eventi-form {
    margin-top: -6.875em;
  }
}
.eventi-form .form-header {
  width: 100%;
  background-color: #960014;
  position: relative;
  display: inline-block;
  color: #fff;
  font-family: "PorscheNext", Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 400;
  padding: 1em;
  margin-bottom: 0;
}

.eventi-form .form-header span {
  font-size: 1.25em;
}

.eventi-form .form-header::after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(204, 0, 0, 0);
  border-top-color: #960014;
  border-width: 20px;
  margin-left: -20px;
}

.eventi-form fieldset:first-of-type {
  margin-top: 0em;
}

.eventi-form label.reset {
  margin: 0;
  padding: 0;
  float: none;
  position: relative;
  clear: both;
}

.eventi-form textarea {
  height: auto;
}

.eventi-form .button {
  margin: 0;
}

/* text & layout */
.title.title--eventi {
  font-size: 26px;
  margin-top: 0;
  margin-bottom: 2rem;
}

.title.title--eventi span {
  font-family: "PorscheNext", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  display: block;
}

/* @media only screen and (max-width: 63.875em) and (min-width: 35.5em){
	.title.title--eventi{
		font-size: 34px;
		line-height: 38px;
	}
	.title.title--eventi span{
		font-size: 21px;
		line-height: 28px;

	}
}
@media only screen and (min-width: 63.9375em){
	.title.title--eventi{
		font-size: 42px;
    line-height: 46px;
	}
	.title.title--eventi span{
		font-size: 26px;
    line-height: 42px;
	}
} */
.sub-title {
  font-family: "PorscheNext", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 18px;
  font-weight: normal;
  position: relative;
  display: block;
  margin: 1em 0 0.923em 0;
  line-height: 24px;
}

.eventi-item {
  position: relative;
  display: block;
  margin: 2rem 0;
}

.eventi-download {
  list-style: none;
  margin: 0;
}

.eventi-download li {
  padding: 0;
}

@media only screen and (min-width: 35.5em) {
  .eventi-download li {
    display: inline-block;
  }
  .eventi-download li:not(:last-child) {
    margin-right: 30px;
  }
}
.video-item {
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
}

.video-item img {
  margin-bottom: 0 !important;
}

.video-item::before {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 52px;
  height: 52px;
  background-image: url("../images/play.html");
  background-repeat: no-repeat;
  background-size: 52px 52px;
  background-position: center center;
  background-color: transparent;
  z-index: 2;
  opacity: 0.8;
  transition: all 0.3s ease-out;
}

.iframe-wrapper {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}

.iframe-wrapper.wrapper--large {
  padding-bottom: 75%;
}

.iframe-wrapper embed,
.iframe-wrapper object,
.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  float: none;
  overflow: auto;
}

footer p {
  font-family: PorscheNext, Arial, Helvetica, sans-serif;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400;
  margin: 16px 0;
}

footer a {
  color: #fff !important;
  text-decoration: underline;
}

footer a:hover, footer a:focus {
  background-color: #404044;
  border-radius: 4px;
  background: var(--States-pds-state-hover, rgba(148, 149, 152, 0.18));
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
}

footer .footer-logo a:hover, footer .footer-logo a:focus {
  background-color: transparent;
}

header {
  display: grid;
  grid-gap: clamp(16px, 1.25vw + 12px, 36px);
  height: 66px;
  max-width: 2560px;
  min-width: 320px;
  border-bottom: 1px solid #e3e4e5;
  grid-template-columns: [full-start] minmax(0, var(--pds-internal-grid-outer-column, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px)))) [wide-start] minmax(0, 1fr) [extended-start] minmax(0, 1fr) [basic-start] repeat(2, minmax(0, 1fr)) [narrow-start] repeat(8, minmax(0, 1fr)) [narrow-end] repeat(2, minmax(0, 1fr)) [basic-end] minmax(0, 1fr) [extended-end] minmax(0, 1fr) [wide-end] minmax(0, var(--pds-internal-grid-outer-column, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px)))) [full-end];
  --pds-internal-grid-safe-zone: calc(5vw - 16px);
  --pds-grid-basic-span-one-half: span 6;
  --pds-grid-basic-span-one-third: span 4;
  --pds-grid-narrow-span-one-half: span 4;
  --pds-grid-basic-span-two-thirds: span 8;
  --pds-grid-extended-span-one-half: span 7;
  padding-bottom: 0 !important;
}
@media (min-width: 760px) {
  header {
    height: 82px;
  }
}
header .wrapper-header {
  height: 100%;
  display: flex;
  justify-content: center;
  grid-column: wide-start/wide-end;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: "left center";
  width: 100%;
}
header .wrapper-header .container-header {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: "left center";
  width: 100%;
}
header .wrapper-header .container-header .wrapper-logo {
  display: flex;
  justify-content: center;
  grid-area: center;
  align-items: center;
}
header .wrapper-header .container-header .wrapper-logo a:hover {
  background: transparent;
}
header .wrapper-header .container-header .wrapper-logo .logo {
  background-color: unset;
  border: unset;
  height: unset;
  width: unset;
  height: clamp(10px, 0.42vw + 8px, 16px) !important;
  display: none;
}
@media (min-width: 760px) {
  header .wrapper-header .container-header .wrapper-logo .logo {
    display: block;
  }
}
header .wrapper-header .container-header .wrapper-logo .logo-mobile {
  max-width: 30px;
  max-height: 40px;
  display: none;
}
@media (max-width: 760px) {
  header .wrapper-header .container-header .wrapper-logo .logo-mobile {
    display: block;
  }
}
header .wrapper-header .container-menu {
  display: flex;
  align-items: center;
}
header .wrapper-header .container-menu .visible-only-mobile {
  display: none;
}
@media (max-width: 760px) {
  header .wrapper-header .container-menu .visible-only-mobile {
    display: block;
  }
}
header .wrapper-header .container-menu a.menu-tools__item {
  text-align: center;
  padding: 0 20px;
  line-height: 14px;
  font-size: 16px;
  flex-basis: 33%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
header .wrapper-header .container-menu a.menu-tools__item p-icon {
  padding: clamp(8px, 0.5vw + 6px, 16px);
}
header .wrapper-header .container-menu a.menu-tools__item:not(:first-child) {
  border-left: 1px solid #fff;
}

.owl-pagination .owl-page {
  padding: 0;
  height: 8px;
  width: 8px;
  margin: 6px 4px 6px;
  border-radius: 4px;
}

.owl-pagination .owl-page span {
  display: block;
  background-color: rgba(27, 29, 31, 0.5);
}

.owl-pagination .owl-page.active {
  transition: width 0.2s ease-in;
  width: 20px;
}

.owl-pagination .owl-page.active span {
  transition: width 0.2s ease-in;
  background-color: #1b1d1f;
  width: 20px;
  border-radius: 4px;
}

#main_slider:hover .owl-buttons {
  opacity: 1%;
}

.owl-buttons .owl-prev:hover,
.owl-buttons .owl-prev:focus,
.owl-buttons .owl-next:hover,
.owl-buttons .owl-next:focus {
  color: #fff;
  background-color: transparent;
}

.owl-pagination .owl-page span {
  background-color: #8d8e8e;
}

.slide-caption h2:before,
.slide h2:before {
  position: absolute;
  margin-left: -1em;
  width: 50px;
  height: 50px;
  content: "";
  background-image: url("/resources/images/arrow-head-white.svg");
  background-size: contain;
  background-repeat: no-repeat;
  line-height: 64px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 35.5625em) {
  .slide-caption h2:before,
  .slide h2:before {
    margin-left: -50px;
  }
}
.footer-note .footer-logo {
  display: flex;
  justify-content: center;
}

.footer-note .footer-logo img {
  margin: 6rem 0;
  position: relative;
  display: inline-block;
  vertical-align: top;
  outline: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  box-sizing: content-box !important;
  height: clamp(0.63rem, 0.42vw + 0.5rem, 1rem) !important;
}

.row {
  grid-template-columns: [full-start] minmax(0, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px))) [wide-start extended-start basic-start narrow-start] repeat(6, minmax(0, 1fr)) [narrow-end basic-end extended-end wide-end] minmax(0, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px))) [full-end];
  --pds-internal-grid-safe-zone: max(22px, 10.625vw - 12px);
  --pds-grid-basic-span-one-half: span 3;
  --pds-grid-basic-span-one-third: span 2;
  --pds-grid-narrow-span-one-half: span 3;
  --pds-grid-basic-span-two-thirds: span 4;
  --pds-grid-extended-span-one-half: span 3;
  margin: 0 var(--pds-internal-grid-margin, 0);
  display: grid;
  padding: 0 calc(50% - var(--pds-internal-grid-margin, 0px) - 1280px);
  grid-gap: clamp(16px, 1.25vw + 12px, 36px);
  max-width: 2560px;
  min-width: 320px;
  box-sizing: content-box;
}
@media (min-width: 760px) {
  .row {
    grid-template-columns: [full-start] minmax(0, var(--pds-internal-grid-outer-column, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px)))) [wide-start] minmax(0, 1fr) [extended-start] minmax(0, 1fr) [basic-start] repeat(2, minmax(0, 1fr)) [narrow-start] repeat(8, minmax(0, 1fr)) [narrow-end] repeat(2, minmax(0, 1fr)) [basic-end] minmax(0, 1fr) [extended-end] minmax(0, 1fr) [wide-end] minmax(0, var(--pds-internal-grid-outer-column, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px)))) [full-end];
    --pds-internal-grid-safe-zone: calc(5vw - 16px);
    --pds-grid-basic-span-one-half: span 6;
    --pds-grid-basic-span-one-third: span 4;
    --pds-grid-narrow-span-one-half: span 4;
    --pds-grid-basic-span-two-thirds: span 8;
    --pds-grid-extended-span-one-half: span 7;
  }
}

.section-container {
  font: 400 1rem/calc(6px + 2.125ex) "PorscheNext", "Arial Narrow", Arial, "Heiti SC", SimHei, sans-serif;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fff;
  overflow: auto;
}

.standard-content {
  /* max-width: 50em; */
  max-width: 100%;
  max-width: 100%;
  grid-column: basic-start/basic-end;
}

.footer-wrapper {
  margin: 0;
  display: grid;
  gap: 0 clamp(16px, 1.25vw + 12px, 36px);
  max-width: 2560px;
  min-width: 320px;
  box-sizing: content-box;
  grid-template-columns: [full-start] minmax(0, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px))) [wide-start extended-start basic-start narrow-start] repeat(6, minmax(0, 1fr)) [narrow-end basic-end extended-end wide-end] minmax(0, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px))) [full-end];
  --pds-internal-grid-safe-zone: max(22px, 10.625vw - 12px);
  --pds-grid-basic-span-one-half: span 3;
  --pds-grid-basic-span-one-third: span 2;
  --pds-grid-narrow-span-one-half: span 3;
  --pds-grid-basic-span-two-thirds: span 4;
  --pds-grid-extended-span-one-half: span 3;
  grid-template-columns: [full-start] minmax(0, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px))) [wide-start extended-start basic-start narrow-start] repeat(6, minmax(0, 1fr)) [narrow-end basic-end extended-end wide-end] minmax(0, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px))) [full-end];
  --pds-internal-grid-safe-zone: max(22px,10.625vw - 12px);
  --pds-grid-basic-span-one-half: span 3;
  --pds-grid-basic-span-one-third: span 2;
  --pds-grid-narrow-span-one-half: span 3;
  --pds-grid-basic-span-two-thirds: span 4;
  --pds-grid-extended-span-one-half: span 3;
  padding: 0 calc(50% - var(--pds-internal-grid-margin, 0px) - 1280px);
}
@media (min-width: 760px) {
  .footer-wrapper {
    grid-template-columns: [full-start] minmax(0, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px))) [wide-start] minmax(0, 1fr) [extended-start] minmax(0, 1fr) [basic-start] repeat(2, minmax(0, 1fr)) [narrow-start] repeat(8, minmax(0, 1fr)) [narrow-end] repeat(2, minmax(0, 1fr)) [basic-end] minmax(0, 1fr) [extended-end] minmax(0, 1fr) [wide-end] minmax(0, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px))) [full-end];
    --pds-internal-grid-safe-zone: calc(5vw - 16px);
    --pds-grid-basic-span-one-half: span 6;
    --pds-grid-basic-span-one-third: span 4;
    --pds-grid-narrow-span-one-half: span 4;
    --pds-grid-basic-span-two-thirds: span 8;
    --pds-grid-extended-span-one-half: span 7;
  }
}
.footer-wrapper .footer-note, .footer-wrapper .footer-assistance-legals {
  display: grid;
  grid-column: extended-start/extended-end;
  clear: both;
}
.footer-wrapper .footer-legals {
  font-size: 16px;
  padding-top: 0;
}
.footer-wrapper .footer-assistance {
  grid-column: revert;
}
.footer-wrapper .footer-assistance .footer-assistance__number {
  font-size: 16px;
}

.footer-legals {
  display: grid;
  grid-column: extended-start/extended-end;
  font-size: 0.875em;
  line-height: 1.5em;
  padding-top: 20px;
}

.footer-legals p {
  margin: 1.4em 0;
}

.footer-legals a {
  color: #fff;
  text-decoration: underline;
  padding: 2px;
}

.footer-assistance .footer-assistance__number a {
  color: #fff;
  padding: 2px;
}

.footer-legals a:hover, .footer-assistance .footer-assistance__number a:hover {
  background-color: #404044;
}

/*--------------------------
PORSCHE NEXT Typeface
--------------------------*/
@font-face {
  font-family: "PorscheNext";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/PorscheNextWLa/PorscheNextWLa-Thin.eot");
  src: url("../fonts/PorscheNextWLa/PorscheNextWLa-Thin.eot") format("embedded-opentype"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Thin.woff2") format("woff2"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Thin.woff") format("woff"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Thin.ttf") format("truetype"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Thin.svg#PorscheNextWLa-Thin") format("svg");
}
@font-face {
  font-family: "PorscheNext";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/PorscheNextWLa/PorscheNextWLa-ThinItalic.eot");
  src: url("../fonts/PorscheNextWLa/PorscheNextWLa-ThinItalic.eot") format("embedded-opentype"), url("../fonts/PorscheNextWLa/PorscheNextWLa-ThinItalic.woff2") format("woff2"), url("../fonts/PorscheNextWLa/PorscheNextWLa-ThinItalic.woff") format("woff"), url("../fonts/PorscheNextWLa/PorscheNextWLa-ThinItalic.ttf") format("truetype"), url("../fonts/PorscheNextWLa/PorscheNextWLa-ThinItalic.svg#PorscheNextWLa-ThinItalic") format("svg");
}
@font-face {
  font-family: "PorscheNext";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/PorscheNextWLa/PorscheNextWLa-Regular.eot");
  src: url("../fonts/PorscheNextWLa/PorscheNextWLa-Regular.eot") format("embedded-opentype"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Regular.woff2") format("woff2"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Regular.woff") format("woff"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Regular.ttf") format("truetype"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Regular.svg#PorscheNextWLa-Regular") format("svg");
}
@font-face {
  font-family: "PorscheNext";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/PorscheNextWLa/PorscheNextWLa-Italic.eot");
  src: url("../fonts/PorscheNextWLa/PorscheNextWLa-Italic.eot") format("embedded-opentype"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Italic.woff2") format("woff2"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Italic.woff") format("woff"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Italic.ttf") format("truetype"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Italic.svg#PorscheNextWLa-Italic") format("svg");
}
@font-face {
  font-family: "PorscheNext";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/PorscheNextWLa/PorscheNextWLa-Bold.eot");
  src: url("../fonts/PorscheNextWLa/PorscheNextWLa-Bold.eot") format("embedded-opentype"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Bold.woff2") format("woff2"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Bold.woff") format("woff"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Bold.ttf") format("truetype"), url("../fonts/PorscheNextWLa/PorscheNextWLa-Bold.svg#PorscheNextWLa-Bold") format("svg");
}
@font-face {
  font-family: "PorscheNext";
  font-weight: 600;
  font-style: italic;
  src: url("../fonts/PorscheNextWLa/PorscheNextWLa-BoldItalic.eot");
  src: url("../fonts/PorscheNextWLa/PorscheNextWLa-BoldItalic.eot") format("embedded-opentype"), url("../fonts/PorscheNextWLa/PorscheNextWLa-BoldItalic.woff2") format("woff2"), url("../fonts/PorscheNextWLa/PorscheNextWLa-BoldItalic.woff") format("woff"), url("../fonts/PorscheNextWLa/PorscheNextWLa-BoldItalic.ttf") format("truetype"), url("../fonts/PorscheNextWLa/PorscheNextWLa-BoldItalic.svg#PorscheNextWLa-BoldItalic") format("svg");
}