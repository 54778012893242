header {
  display: grid;
  grid-gap: clamp(16px, 1.25vw + 12px, 36px);
  height: 66px;
  max-width: 2560px;
  min-width: 320px;
  border-bottom: 1px solid #e3e4e5;
  grid-template-columns: [full-start] minmax(0, var(--pds-internal-grid-outer-column, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px)))) [wide-start] minmax(0, 1fr) [extended-start] minmax(0, 1fr) [basic-start] repeat(2, minmax(0, 1fr)) [narrow-start] repeat(8, minmax(0, 1fr)) [narrow-end] repeat(2, minmax(0, 1fr)) [basic-end] minmax(0, 1fr) [extended-end] minmax(0, 1fr) [wide-end] minmax(0, var(--pds-internal-grid-outer-column, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px)))) [full-end];
  --pds-internal-grid-safe-zone: calc(5vw - 16px);
  --pds-grid-basic-span-one-half: span 6;
  --pds-grid-basic-span-one-third: span 4;
  --pds-grid-narrow-span-one-half: span 4;
  --pds-grid-basic-span-two-thirds: span 8;
  --pds-grid-extended-span-one-half: span 7;
  padding-bottom: 0 !important;

  @media(min-width: 760px) {
    height: 82px;
  }

  .wrapper-header {
    height: 100%;
    display: flex;
    justify-content: center;
    grid-column: wide-start/wide-end;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: "left center";
    width: 100%;
    .container-header {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-template-areas: "left center";
      width: 100%;
      .wrapper-logo {
        display: flex;
        justify-content: center;
        grid-area: center;
        align-items: center;
        a:hover {
          background: transparent;
        }
        .logo {
          background-color: unset;
          border: unset;
          height: unset;
          width: unset;
          height: clamp(10px, 0.42vw + 8px, 16px) !important;
          display: none;
          @media(min-width: 760px) {
            display: block;
          }
        }
        .logo-mobile {
          max-width: 30px;
          max-height: 40px;
          display: none;
          @media(max-width: 760px) {
            display: block;
          }

        }
      }
    }
    .container-menu {
      display: flex;
      align-items: center;
      .visible-only-mobile {
        display: none;
        @media(max-width: 760px) {
          display: block;
        }
      }
      a.menu-tools__item {
        text-align: center;
        padding: 0 20px;
        line-height: 14px;
        font-size: 16px;
        flex-basis: 33%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        p-icon {
          padding: clamp(8px, 0.5vw + 6px, 16px);
        }
        &:not(:first-child) {
          border-left: 1px solid #fff
        }
      }
    }
  }
}



//.teaser-slider .owl-pagination .owl-page {
.owl-pagination .owl-page {
  padding: 0;
  height: 8px;
  width: 8px;
  margin: 6px 4px 6px;
  border-radius: 4px;
}

//.teaser-slider .owl-pagination .owl-page span {
.owl-pagination .owl-page span {
  display: block;
  background-color: rgba(27, 29, 31, .5);
}

//.teaser-slider .owl-pagination .owl-page.active{
.owl-pagination .owl-page.active {
  transition: width 0.2s ease-in;
  width: 20px;
}

//.teaser-slider .owl-pagination .owl-page.active span {
.owl-pagination .owl-page.active span {
  transition: width 0.2s ease-in;
  background-color: #1b1d1f;
  width: 20px;
  border-radius: 4px;
}

#main_slider:hover .owl-buttons {
  opacity: 1%;
}
.owl-buttons .owl-prev:hover,
.owl-buttons .owl-prev:focus,
.owl-buttons .owl-next:hover,
.owl-buttons .owl-next:focus {
  color: #fff;
  background-color: transparent;
}

.owl-pagination .owl-page span{
  background-color: #8d8e8e;
}

.slide-caption h2:before,
.slide h2:before {
  position: absolute;
  margin-left: -1em;
  width: 50px;
  height: 50px;
  content: "";
  background-image: url('/resources/images/arrow-head-white.svg');
  background-size: contain;
  background-repeat: no-repeat;
  line-height: 64px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 35.5625em) {
  .slide-caption h2:before,
  .slide h2:before {
    margin-left: -50px;
  }
}

.footer-note .footer-logo {
  display: flex;
  justify-content: center;
}

.footer-note .footer-logo img {
  margin: 6rem 0;
  position: relative;
  display: inline-block;
  vertical-align: top;
  outline: 0!important;
  max-width: 100%!important;
  max-height: 100%!important;
  box-sizing: content-box!important;
  height: clamp(.63rem,.42vw + .5rem,1rem)!important;
}

.row {
  grid-template-columns: [full-start] minmax(0,calc(var(--pds-internal-grid-safe-zone) - clamp(16px,1.25vw + 12px,36px))) [wide-start extended-start basic-start narrow-start] repeat(6,minmax(0,1fr)) [narrow-end basic-end extended-end wide-end] minmax(0,calc(var(--pds-internal-grid-safe-zone) - clamp(16px,1.25vw + 12px,36px))) [full-end];
  --pds-internal-grid-safe-zone: max(22px, 10.625vw - 12px);
  --pds-grid-basic-span-one-half: span 3;
  --pds-grid-basic-span-one-third: span 2;
  --pds-grid-narrow-span-one-half: span 3;
  --pds-grid-basic-span-two-thirds: span 4;
  --pds-grid-extended-span-one-half: span 3;
  margin: 0 var(--pds-internal-grid-margin, 0);
  display: grid;
  padding: 0 calc(50% - var(--pds-internal-grid-margin, 0px) - 1280px);
  grid-gap: clamp(16px, 1.25vw + 12px, 36px);
  max-width: 2560px;
  min-width: 320px;
  box-sizing: content-box;
  @media(min-width: 760px) {
    grid-template-columns: [full-start] minmax(0, var(--pds-internal-grid-outer-column, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px)))) [wide-start] minmax(0, 1fr) [extended-start] minmax(0, 1fr) [basic-start] repeat(2, minmax(0, 1fr)) [narrow-start] repeat(8, minmax(0, 1fr)) [narrow-end] repeat(2, minmax(0, 1fr)) [basic-end] minmax(0, 1fr) [extended-end] minmax(0, 1fr) [wide-end] minmax(0, var(--pds-internal-grid-outer-column, calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px)))) [full-end];
    --pds-internal-grid-safe-zone: calc(5vw - 16px);
    --pds-grid-basic-span-one-half: span 6;
    --pds-grid-basic-span-one-third: span 4;
    --pds-grid-narrow-span-one-half: span 4;
    --pds-grid-basic-span-two-thirds: span 8;
    --pds-grid-extended-span-one-half: span 7;
  }
}

.section-container {
  font: 400 1rem / calc(6px + 2.125ex) 'PorscheNext', 'Arial Narrow', Arial, 'Heiti SC', SimHei, sans-serif;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fff;
  overflow: auto;
}

.standard-content {
  /* max-width: 50em; */
  max-width: 100%;
  max-width: 100%;
  grid-column: basic-start/basic-end;
}

.footer-wrapper {
  margin: 0;
  display: grid;
  gap: 0 clamp(16px,1.25vw + 12px,36px);
  max-width: 2560px;
  min-width: 320px;
  box-sizing: content-box;
  grid-template-columns: [full-start] minmax(0,calc(var(--pds-internal-grid-safe-zone) - clamp(16px,1.25vw + 12px,36px))) [wide-start extended-start basic-start narrow-start] repeat(6,minmax(0,1fr)) [narrow-end basic-end extended-end wide-end] minmax(0,calc(var(--pds-internal-grid-safe-zone) - clamp(16px,1.25vw + 12px,36px))) [full-end];
  --pds-internal-grid-safe-zone: max(22px, 10.625vw - 12px);
  --pds-grid-basic-span-one-half: span 3;
  --pds-grid-basic-span-one-third: span 2;
  --pds-grid-narrow-span-one-half: span 3;
  --pds-grid-basic-span-two-thirds: span 4;
  --pds-grid-extended-span-one-half: span 3;
  grid-template-columns: [full-start] minmax(0,calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px))) [wide-start extended-start basic-start narrow-start] repeat(6,minmax(0,1fr)) [narrow-end basic-end extended-end wide-end] minmax(0,calc(var(--pds-internal-grid-safe-zone) - clamp(16px, 1.25vw + 12px, 36px))) [full-end];
  --pds-internal-grid-safe-zone: max(22px,10.625vw - 12px);
  --pds-grid-basic-span-one-half: span 3;
  --pds-grid-basic-span-one-third: span 2;
  --pds-grid-narrow-span-one-half: span 3;
  --pds-grid-basic-span-two-thirds: span 4;
  --pds-grid-extended-span-one-half: span 3;
  padding: 0 calc(50% - var(--pds-internal-grid-margin, 0px) - 1280px);
  @media(min-width: 760px) {
    grid-template-columns: [full-start] minmax(0,calc(var(--pds-internal-grid-safe-zone) - clamp(16px,1.25vw + 12px,36px))) [wide-start] minmax(0,1fr) [extended-start] minmax(0,1fr) [basic-start] repeat(2,minmax(0,1fr)) [narrow-start] repeat(8,minmax(0,1fr)) [narrow-end] repeat(2,minmax(0,1fr)) [basic-end] minmax(0,1fr) [extended-end] minmax(0,1fr) [wide-end] minmax(0,calc(var(--pds-internal-grid-safe-zone) - clamp(16px,1.25vw + 12px,36px))) [full-end];
    --pds-internal-grid-safe-zone: calc(5vw - 16px);
    --pds-grid-basic-span-one-half: span 6;
    --pds-grid-basic-span-one-third: span 4;
    --pds-grid-narrow-span-one-half: span 4;
    --pds-grid-basic-span-two-thirds: span 8;
    --pds-grid-extended-span-one-half: span 7;
  }
  .footer-note, .footer-assistance-legals {
    display: grid;
    grid-column: extended-start/extended-end;
    clear: both;
  }

  .footer-legals {
    font-size: 16px;
    padding-top: 0;
  }

  .footer-assistance {
    grid-column: revert;
    .footer-assistance__number {
      font-size: 16px;
    }
  }
}


.footer-legals {
  display: grid;
  grid-column: extended-start/extended-end;
  font-size: 0.875em;
  line-height: 1.500em;
  padding-top: 20px;

}

.footer-legals p {
  margin: 1.4em 0;
}

.footer-legals a {
  color: #fff;
  text-decoration: underline;
  padding: 2px;
}

.footer-assistance .footer-assistance__number a {
  color: #fff;
  padding: 2px;
}

.footer-legals a:hover, .footer-assistance .footer-assistance__number a:hover {
  background-color: #404044;
}


/*--------------------------
PORSCHE NEXT Typeface
--------------------------*/
@font-face {
  font-family: 'PorscheNext';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/PorscheNextWLa/PorscheNextWLa-Thin.eot');
  src: url('../fonts/PorscheNextWLa/PorscheNextWLa-Thin.eot') format('embedded-opentype'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Thin.woff2') format('woff2'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Thin.woff') format('woff'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Thin.ttf') format('truetype'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Thin.svg#PorscheNextWLa-Thin') format('svg');
}

@font-face {
  font-family: 'PorscheNext';
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/PorscheNextWLa/PorscheNextWLa-ThinItalic.eot');
  src: url('../fonts/PorscheNextWLa/PorscheNextWLa-ThinItalic.eot') format('embedded-opentype'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-ThinItalic.woff2') format('woff2'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-ThinItalic.woff') format('woff'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-ThinItalic.ttf') format('truetype'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-ThinItalic.svg#PorscheNextWLa-ThinItalic') format('svg');
}

@font-face {
  font-family: 'PorscheNext';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/PorscheNextWLa/PorscheNextWLa-Regular.eot');
  src: url('../fonts/PorscheNextWLa/PorscheNextWLa-Regular.eot') format('embedded-opentype'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Regular.woff2') format('woff2'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Regular.woff') format('woff'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Regular.ttf') format('truetype'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Regular.svg#PorscheNextWLa-Regular') format('svg');
}

@font-face {
  font-family: 'PorscheNext';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/PorscheNextWLa/PorscheNextWLa-Italic.eot');
  src: url('../fonts/PorscheNextWLa/PorscheNextWLa-Italic.eot') format('embedded-opentype'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Italic.woff2') format('woff2'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Italic.woff') format('woff'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Italic.ttf') format('truetype'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Italic.svg#PorscheNextWLa-Italic') format('svg');
}

@font-face {
  font-family: 'PorscheNext';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/PorscheNextWLa/PorscheNextWLa-Bold.eot');
  src: url('../fonts/PorscheNextWLa/PorscheNextWLa-Bold.eot') format('embedded-opentype'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Bold.woff2') format('woff2'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Bold.woff') format('woff'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Bold.ttf') format('truetype'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-Bold.svg#PorscheNextWLa-Bold') format('svg');
}

@font-face {
  font-family: 'PorscheNext';
  font-weight: 600;
  font-style: italic;
  src: url('../fonts/PorscheNextWLa/PorscheNextWLa-BoldItalic.eot');
  src: url('../fonts/PorscheNextWLa/PorscheNextWLa-BoldItalic.eot') format('embedded-opentype'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-BoldItalic.woff2') format('woff2'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-BoldItalic.woff') format('woff'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-BoldItalic.ttf') format('truetype'),
  url('../fonts/PorscheNextWLa/PorscheNextWLa-BoldItalic.svg#PorscheNextWLa-BoldItalic') format('svg');
}